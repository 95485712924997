import {
  getOperationLists,
  deleteSelectOperation,
  getAuditLists,
  getTraceLists,
  deleteSelectAudit,
  deleteSelectTrace,
  getTerminalLogLists
} from '../../service/log'

export default {
  namespaced: true,
  state: () => ({
    operationCount: 0,
    operationList: [],
    auditCount: 0,
    auditList: [],
    tracesCount: 0,
    tracesList: [],
    terminalDownloadList: [],
    terminalDownloadCount: 0
  }),
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    setOperationsCount(state, operationCount) {
      state.operationCount = operationCount
    },
    setOperationsList(state, operationList) {
      state.operationList = operationList
    },
    setAuditCount(state, auditCount) {
      state.auditCount = auditCount
    },
    setAuditList(state, auditList) {
      state.auditList = auditList
    },
    setTracesCount(state, tracesCount) {
      state.tracesCount = tracesCount
    },
    setTracesList(state, tracesList) {
      state.tracesList = tracesList
    },
    setDownloadCount(state, terminalDownloadCount) {
      state.terminalDownloadCount = terminalDownloadCount
    },
    setDownloadList(state, terminalDownloadList) {
      state.terminalDownloadList = terminalDownloadList
    }
  },
  actions: {
    async getOperationListData(context, payload) {
      const res = await getOperationLists(payload)
      this.commit('log/setOperationsList', res?.data?.data?.records || [])
      this.commit('log/setOperationsCount', res?.data?.data?.total || 0)
      return res
    },
    async deleteMultiOperation({ dispatch }, payload) {
      const res = await deleteSelectOperation(payload)
      return res
    },
    async deleteMultiAudit({ dispatch }, payload) {
      const res = await deleteSelectAudit(payload)
      return res
    },
    async deleteMultiTrace({ dispatch }, payload) {
      const res = await deleteSelectTrace(payload)
      return res
    },
    async getAuditListData(context, payload) {
      const res = await getAuditLists(payload)
      this.commit('log/setAuditList', res?.data?.data?.records || [])
      this.commit('log/setAuditCount', res?.data?.data?.total || 0)
      return res
    },
    async getTraceListData(context, payload) {
      const res = await getTraceLists(payload)
      this.commit('log/setTracesList', res?.data?.data?.records || [])
      this.commit('log/setTracesCount', res?.data?.data?.total || 0)
      return res
    },
    async getTerminalLogLists(context, payload) {
      const res = await getTerminalLogLists(payload)
      this.commit('log/setDownloadList', res?.data?.data?.records || [])
      this.commit('log/setDownloadCount', res?.data?.data?.total || 0)
      return res
    }
  }
}
