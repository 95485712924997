<template>
  <div class="tags-view-container">
    <el-scrollbar v-if="isShowScrollBar" class="scrollbar" ref="scrollbar">
      <draggable
        v-model="$store.state.app.tagsViewList"
        animation="1000"
        @start="onStart"
        @end="onEnd"
        itemKey="name"
      >
        <template #item="{ element, index }">
          <router-link
            class="tags-view-item"
            :to="{ path: element.fullPath }"
            :class="isActive(element) ? 'active' : ''"
            :style="{
              backgroundColor: isActive(element)
                ? $store.getters.cssVar.menuBg
                : '',
              borderColor: isActive(element) ? $store.getters.cssVar.menuBg : ''
            }"
            @contextmenu.prevent="openMenu($event, index)"
          >
            {{ element.title }}
            <em
              v-show="!isActive(element)"
              class="el-icon-close"
              @click.prevent.stop="onCloseClick(index)"
            ></em>
          </router-link>
        </template>
      </draggable>
    </el-scrollbar>
    <!-- 标签右键菜单 -->
    <context-menu
      v-show="isVisible"
      :style="menuStyle"
      :index="selectIndex"
    ></context-menu>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  watch,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
  nextTick
} from 'vue'
import { useRoute } from 'vue-router'
import ContextMenu from './ContextMenu.vue'
import { useStore } from 'vuex'
import draggable from 'vuedraggable'
import { setItem } from '@/utils/storage'

const route = useRoute()
const store = useStore()
const drag = ref(false)
// 判断当前对象是否与路由对应
const isActive = (tag) => tag.path === route.path
const scrollbar = ref('')
const { appContext } = getCurrentInstance()
const bus = appContext.config.globalProperties.$bus
const isShowScrollBar = ref(true)
// 处理关闭tag的事件
const onCloseClick = (index) => {
  store.commit('app/removeTagsView', {
    type: 'index',
    index: index
  })
}

const scrollActiveTag = () => {
  // 让组件重新渲染，否则拿到的activeViewTag每次都是第一次的
  isShowScrollBar.value = false
  nextTick(() => {
    isShowScrollBar.value = true
  })
  // 设置下延迟 否则获取到的activeViewTag不是最新的
  setTimeout(() => {
    const activeViewTag = document.querySelector('.router-link-exact-active')
    if (activeViewTag) {
      scrollbar.value.wrap.scrollTop = activeViewTag.offsetTop
      if (activeViewTag.offsetTop < scrollbar.value.wrap.offsetHeight / 2) {
        scrollbar.value.wrap.scrollTop = 0
      } else {
        scrollbar.value.wrap.scrollTop = scrollbar.value.wrap.scrollHeight
      }
    }
  }, 0)
}
// 开始拖拽事件
const onStart = () => {
  drag.value = true
}
// 拖拽结束事件
const onEnd = () => {
  drag.value = false
  setItem('tagsView', store.state.app.tagsViewList)
}
bus.on('scrollToActiveTag', () => {
  scrollActiveTag()
})

onBeforeUnmount(() => {
  bus.off('scrollToActiveTag')
})
const selectIndex = ref(0)
const isVisible = ref(false)
const menuStyle = reactive({
  left: 0,
  top: 0
})

const openMenu = (e, index) => {
  const { x, y } = e
  menuStyle.left = x + 'px'
  menuStyle.top = y + 'px'
  selectIndex.value = index
  isVisible.value = true
}

const closeMenu = () => {
  isVisible.value = false
}
onMounted(() => {
  scrollActiveTag()
})
watch(isVisible, (val) => {
  if (val) {
    document.body.addEventListener('click', closeMenu)
  } else {
    document.body.removeEventListener('click', closeMenu)
  }
})
</script>

<style lang="scss" scoped>
.tags-view-container {
  height: 36px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #d8dce5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);

  :deep(.scrollbar) {
    .el-scrollbar__view {
      margin-left: 12px;
      margin-bottom: 3px;
    }
  }

  .tags-view-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 26px;
    line-height: 26px;
    border: 1px solid #d8dce5;
    color: #495060;
    background: #fff;
    padding: 0 8px;
    font-size: 12px;
    margin-left: 5px;
    margin-top: 4px;
    &:last-of-type {
      margin-right: 15px;
    }
    &.active {
      color: #fff;
      &::before {
        content: '';
        background: #fff;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: relative;
        margin-right: 4px;
      }
    }
    /**close 按钮 */
    .el-icon-close {
      width: 16px;
      height: 16px;
      line-height: 10px;
      vertical-align: 2px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;
      &:before {
        transform: scale(0.6);
        display: inline-block;
        vertical-align: -3px;
      }
      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
    }
  }
}
</style>
