import { watch } from 'vue'
import i18n from '@/i18n'
import store from '@/store'

export const generateTitle = (title) => i18n.global.t('general.router-' + title)
export const generateParentTitle = (title) => i18n.global.t('common.' + title)

export const watchSwitchLang = (...cbs) => {
  watch(
    () => store.getters.language,
    () => {
      cbs.forEach((cb) => cb(store.getters.language))
    }
  )
}
