import request from '../utils/request'
import qs from 'qs'

export const login = (data) => {
  return request({
    url: `/auth/login?randNum=${data.randomNum}&validateCode=${data.randCode}`,
    data: data,
    method: 'post'
  })
}

// 获取用户信息
export const getUserInfo = () => {
  return request({
    url: '/rest/user/profile',
    method: 'get'
  })
}
// My Profile 更新
export const updateProfileInfo = (data) => {
  return request({
    url: `/rest/user/profile/update`,
    data: data,
    method: 'post'
  })
}

export const updateThemeColor = (data) => {
  return request({
    url: `/rest/user/profile/updateThemeColor`,
    data,
    method: 'post'
  })
}

export const changePassword = (data) => {
  return request({
    url: `/rest/user/profile/changePassword`,
    data: data,
    method: 'post'
  })
}
// 这两个接口区别是changePassword需要输入原密码，resetPassword不需要输入原密码
export const resetPassword = (data) => {
  return request({
    url: `/rest/user/profile/resetPassword`,
    data: data,
    method: 'post'
  })
}

export const getPasswordExpiry = () => {
  return request({
    url: `/rest/authen/getExpiry`,
    method: 'get'
  })
}

export const getPasswordExpiryDay = () => {
  return request({
    url: `/rest/user/profile/resetDays`,
    method: 'get'
  })
}

// 获取左侧菜单信息
export const getMenuInfo = () => {
  return request({
    url: '/rest/resource/mine',
    // url: '/mock/menu.json',
    method: 'post'
  })
}

// 获取用户列表
export const getUserList = () => {
  return request({
    url: '/rest/user/option',
    method: 'get'
  })
}

// 获取分页用户列表
export const getUserListByPage = (data) => {
  return request({
    url: `/rest/user/getPage`,
    data,
    method: 'post'
  })
}

// 获取terminalList Data
export const getTerminalList = (data) => {
  return request({
    url: '/rest/terminal/getpage',
    data: data,
    method: 'post'
  })
}

// 登出接口
export const logoutSystem = () => {
  return request({
    url: '/auth/logout',
    method: 'get'
  })
}

export const getSessionTimeout = (sid) => {
  return request({
    url: `/auth/session`,
    method: 'get',
    otherOptions: {
      headers: { Accept: 'application/json' }
    }
  })
}

// 获取所有有权限的按钮 返回格式为['user.users.edit','user.users.view']
export const getAllBtnPermission = () => {
  return request({
    url: `/rest/authen/btn/init`,
    method: 'get'
  })
}

// 获取所有有权限的菜单
export const getAllMenuPermission = () => {
  return request({
    url: `/rest/authen/url/init`,
    method: 'get'
  })
}

// 获取客户列表
export const getCustomerList = (data = {}) => {
  return request({
    url: `/rest/customer/get`,
    data: data,
    method: 'post'
  })
}

// 获取商户列表
export const getMerchantList = (data = {}) => {
  return request({
    url: `/rest/merchant/option`,
    data,
    method: 'post'
  })
}

// 获取角色列表
export const getRoleList = () => {
  return request({
    url: `/rest/role/option`,
    method: 'get'
  })
}

// 获取分页角色列表
export const getRoleListByPage = (data) => {
  return request({
    url: `/rest/role/getPage`,
    data,
    method: 'post'
  })
}

// 添加用户提交按钮
export const handleAddUserSubmit = (data) => {
  return request({
    url: `/rest/user/add`,
    data,
    method: 'post'
  })
}

// 获取查看用户详情通过name
export const getUserByName = (data) => {
  return request({
    url: `/rest/admin/users/view/` + data.name + '/',
    method: 'get'
  })
}

// 获取编辑用户详情通过id
export const getUserDetailById = (data) => {
  return request({
    url: `/rest/user/get/` + data.id,
    method: 'get'
  })
}

// 修改用户提交按钮
export const handleEditUserSubmit = (data) => {
  return request({
    url: `/rest/user/edit`,
    data,
    method: 'put'
  })
}

// 删除用户
export const deleteUserById = (id) => {
  return request({
    url: `/rest/user/del/${id}`,
    method: 'delete'
  })
}

export const handleDisableUser = (id) => {
  return request({
    url: `/rest/user/disable/${id}`,
    method: 'put'
  })
}
export const handleEnableUser = (id) => {
  return request({
    url: `/rest/user/enable/${id}`,
    method: 'put'
  })
}

export const handleResetPassword = (data) => {
  return request({
    url: `/rest/user/resetPassword/${data.id}`,
    method: 'put'
  })
}

// 查找用户日志
export const getUserLogs = (data) => {
  return request({
    url: '/rest/admin/logs/page',
    data: qs.stringify(data),
    method: 'post',
    otherOptions: {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  })
}

// 获取资源列表
export const getGroupList = () => {
  return request({
    url: '/rest/resourceGroup/option',
    method: 'get'
  })
}

// 获取资源分页列表
export const getGroupListByPage = (data) => {
  return request({
    url: `/rest/resourceGroup/getPage`,
    data,
    method: 'post'
  })
}

// 添加角色提交按钮
export const handleAddRoleSubmit = (data) => {
  return request({
    url: `/rest/role/add`,
    data,
    method: 'post'
  })
}

// 获取角色详情
export const getCurrentRoleList = (data) => {
  return request({
    url: `/rest/role/get/` + data.id,
    method: 'get'
  })
}

// 编辑角色提交按钮
export const handleEditRoleSubmit = (data) => {
  return request({
    url: `/rest/role/edit`,
    data,
    method: 'put'
  })
}

// 删除角色
export const deleteRoleById = (id) => {
  return request({
    url: `/rest/role/del/${id}`,
    method: 'delete'
  })
}

// 获取含有按钮的资源菜单列表
export const getResourceMenuBtnList = () => {
  return request({
    url: '/rest/resource/menu',
    method: 'post'
  })
}

// 获取所有有权限按钮列表
export const getPermissionBtnList = (id) => {
  return request({
    url: `/rest/resourceGroup/btn/${id}`,
    method: 'post'
  })
}

// 获取所有有权限菜单列表
export const getPermissionMenuList = (id) => {
  return request({
    url: `/rest/resourceGroup/menu/${id}`,
    method: 'post'
  })
}

// 通过ID查找资源组
export const getResourceGroupById = (id) => {
  return request({
    url: `/rest/resourceGroup/get/${id}`,
    method: 'get'
  })
}

// 通过ID获取按钮列表
export const getBtnListById = (id) => {
  return request({
    url: `/rest/admin/resources/btn/${id}`,
    method: 'get'
  })
}

// 编辑资源提交按钮
export const handleEditResourceSubmit = (data) => {
  return request({
    url: `/rest/resourceGroup/edit`,
    data,
    method: 'put'
  })
}

// 新增资源提交按钮
export const handleAddResourceSubmit = (data) => {
  return request({
    url: '/rest/resourceGroup/add',
    data,
    method: 'post'
  })
}

// 删除资源提交按钮
export const handleDeleteResourceSubmit = (id) => {
  return request({
    url: `/rest/resourceGroup/del/${id}`,
    method: 'delete'
  })
}
