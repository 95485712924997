import layout from '@/Layout/index.vue'

export default {
  path: '/home',
  component: layout,
  redirect: '/home',
  meta: {
    title: 'home'
  },
  children: [
    {
      path: '/report/customers',
      name: 'customerReport',
      component: () =>
        import('@/views/report/customer-report/customer-report.vue'),
      meta: {
        title: 'customer-report',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-report-center'
          }
        ]
      }
    },
    {
      path: '/report/apps',
      name: 'applicationReport',
      component: () =>
        import('@/views/report/application-report/application-report.vue'),
      meta: {
        title: 'application-report',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-report-center'
          }
        ]
      }
    },
    {
      path: '/report/merchants',
      name: 'merchantReport',
      component: () =>
        import('@/views/report/merchant-report/merchant-report.vue'),
      meta: {
        title: 'merchant-report',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-report-center'
          }
        ]
      }
    },
    {
      path: '/report/terminals',
      name: 'terminalReport',
      component: () =>
        import('@/views/report/terminal-report/terminal-report.vue'),
      meta: {
        title: 'terminal-report',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-report-center'
          }
        ]
      }
    }
  ]
}
