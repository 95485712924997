<template>
  <div :class="{ show: isShow }" class="header-search">
    <svg-icon
      class-name="search-icon"
      icon="search"
      @click.stop="onShowClick"
      style="
        width: 20px;
        height: 20px;
        display: table-cell;
        vertical-align: middle;
      "
    />
    <el-select
      ref="headerSearchSelectRef"
      class="header-search-select"
      v-model="search"
      filterable
      default-first-option
      remote
      placeholder="Search"
      :remote-method="querySearch"
      @change="onSelectChange"
    >
      <el-option
        v-for="option in searchOptions"
        :key="option.item.path"
        :label="option.item.title.join(' > ')"
        :value="option.item"
      ></el-option>
    </el-select>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Fuse from 'fuse.js'
import { generateTitle, generateParentTitle } from '@/utils/i18n'

// 控制search的显示
const isShow = ref(false)
// el-select实例
const headerSearchSelectRef = ref(null)
const onShowClick = () => {
  isShow.value = !isShow.value
  headerSearchSelectRef.value.focus()
}
// search相关
const search = ref('')
// 搜索结果
const searchOptions = ref([])
// 搜索方法
const querySearch = (query) => {
  if (query !== '') {
    searchOptions.value = fuse.value.search(query)
  } else {
    searchOptions.value = []
  }
}

// 将输入框中内容清空
const resetInput = () => {
  search.value = ''
  searchOptions.value = []
  isShow.value = false
}
// 选中回调
const onSelectChange = (val) => {
  router.push(val.path)
  resetInput()
}

// 检索数据源
const router = useRouter()

const result = ref([])
const handleDataSource = () => {
  // 重置数据源
  result.value = []
  router.getRoutes().forEach((item) => {
    // 三级 例如view、edit等页面不允许通过路由搜索
    if (item.meta.searchFlag && !item.meta.activeMenu) {
      const { path, meta } = item
      const obj = {}
      const titles = []
      obj.path = path
      if (meta.parentTitle) {
        meta.parentTitle.forEach((item) =>
          titles.push(generateParentTitle(item.title))
        )
      }
      titles.push(generateTitle(meta.title))

      obj.title = titles
      result.value.push(obj)
    }
  })
}

handleDataSource()
const store = useStore()
watch(
  () => store.getters.language,
  () => {
    handleDataSource()
    searchOptions.value = []
  }
)

/**
 * 搜索库相关
 */
const fuse = ref(null)

watch(
  () => result.value,
  () => {
    fuse.value = new Fuse(result.value, {
      // 是否按优先级进行排序
      shouldSort: true,
      // 匹配长度超过这个值的才会被认为是匹配的
      minMatchCharLength: 2,
      // 将被搜索的键列表。 这支持嵌套路径、加权搜索、在字符串和对象数组中搜索。
      // name：搜索的键
      // weight：对应的权重
      keys: [
        {
          name: 'title',
          weight: 0.7
        },
        {
          name: 'path',
          weight: 0.3
        }
      ]
    })
  },
  {
    immediate: true,
    deep: true
  }
)
</script>

<style lang="scss" scoped>
.header-search {
  font-size: 0 !important;
  .search-icon {
    cursor: pointer;
    font-size: 16px;
    vertical-align: middle;
  }
  .header-search-select {
    font-size: 16px;
    transition: width 0.2s;
    /**
      先固定住，后面采取动画的方式来书写
      */
    width: 0px;
    // margin-left:10px;
    overflow: hidden;
    background: transparent;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;
    ::v-deep .el-input__inner {
      border-radius: 0;
      border: 0;
      padding-left: 0;
      padding-right: 0;
      box-shadow: none !important;
      border-bottom: 1px solid #d9d9d9;
      vertical-align: middle;
    }
  }
  &.show {
    .header-search-select {
      width: 180px;
      margin-left: 10px;
    }
  }
}
</style>
