<template>
  <div class="export-model">
    <el-dropdown
      v-if="isShowDropDown"
      size="small"
      split-button
      type="primary"
      v-throttle="5000"
      @click.prevent="download()"
      @command="changeReportType"
      trigger="click"
    >
      <em class="fa fa-cloud-download fa-fw"></em>
      {{ $t('general.export') }}
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            command="doc"
            :icon="reporttype == 'doc' ? 'el-icon-check' : ''"
            >{{ $t('general.export-word') }}</el-dropdown-item
          >
          <el-dropdown-item
            command="xls"
            :icon="reporttype == 'xls' ? 'el-icon-check' : ''"
            >{{ $t('general.export-excel') }}</el-dropdown-item
          >
          <el-dropdown-item
            command="pdf"
            :icon="reporttype == 'pdf' ? 'el-icon-check' : ''"
            >{{ $t('general.export-pdf') }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-button
      v-else
      icon="el-icon-download"
      type="primary"
      size="small"
      @click.prevent="download()"
      >{{ $t('general.export') }}</el-button
    >
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'

const props = defineProps({
  isShowDropDown: {
    type: Boolean,
    default: false
  }
})
const reporttype = ref('pdf')

const emit = defineEmits(['exportReport'])

const changeReportType = (reportType) => {
  reporttype.value = reportType
}

const download = () => {
  emit('exportReport', {
    reporttype: props.isShowDropDown ? reporttype.value : ''
  })
}
</script>
