/*****
 * @desc use to handle the context of user
 * @author Mandy
 * @date 2023-4-25
 */
import {
  getGeoFenceListByPage,
  handleNewGeoFence,
  handleUpdateGeoFence,
  handleDeleteGeoFence,
  getGeoFenceById,
  getGeoFenceGroupListByPage,
  handleNewGeoFenceGroup,
  handleUpdateGeoFenceGroup,
  handleDeleteGeoFenceGroup,
  getGeoFenceGroupById,
  handleNewGeoFenceGroupSetting,
  handleUpdateGeoFenceGroupSetting,
  handleDeleteGeoFenceGroupSetting,
  getGeoFenceGroupSettingById,
  getGeoFenceSettingListByPage
} from '@/service/geoFence'

export default {
  namespaced: true,
  state: () => ({
    geoFenceList: [],
    geoFenceCount: 0,
    groupList: [],
    groupCount: 0,
    settingList: [],
    settingCount: 0
  }),
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    setGeoFenceList(state, geoFenceList) {
      state.geoFenceList = geoFenceList
    },
    setGeoFenceCount(state, geoFenceCount) {
      state.geoFenceCount = geoFenceCount
    },
    setGroupList(state, groupList) {
      state.groupList = groupList
    },
    setGroupCount(state, groupCount) {
      state.groupCount = groupCount
    },
    setSettingList(state, settingList) {
      state.settingList = settingList
    },
    setSettingCount(state, settingCount) {
      state.settingCount = settingCount
    }
  },
  actions: {
    async getGeoFenceListByPage(context, payload) {
      const res = await getGeoFenceListByPage(payload)
      this.commit('geoFence/setGeoFenceList', res?.data?.data?.records || [])
      this.commit('geoFence/setGeoFenceCount', res?.data?.data?.total || 0)
      return res
    },
    async handleNewGeoFence(context, payload) {
      const res = await handleNewGeoFence(payload)
      return res
    },
    async handleDeleteGeoFence(context, payload) {
      const res = await handleDeleteGeoFence(payload)
      return res
    },
    async handleUpdateGeoFence(context, payload) {
      const res = await handleUpdateGeoFence(payload)
      return res
    },
    async getGeoFenceById(context, payload) {
      const res = await getGeoFenceById(payload)
      return res?.data?.data || {}
    },
    async getGeoFenceGroupListByPage(context, payload) {
      const res = await getGeoFenceGroupListByPage(payload)
      this.commit('geoFence/setGroupList', res?.data?.data?.records || [])
      this.commit('geoFence/setGroupCount', res?.data?.data?.total || 0)
      return res
    },
    async handleNewGeoFenceGroup(context, payload) {
      const res = await handleNewGeoFenceGroup(payload)
      return res
    },
    async handleDeleteGeoFenceGroup(context, payload) {
      const res = await handleDeleteGeoFenceGroup(payload)
      return res
    },
    async handleUpdateGeoFenceGroup(context, payload) {
      const res = await handleUpdateGeoFenceGroup(payload)
      return res
    },
    async getGeoFenceGroupById(context, payload) {
      const res = await getGeoFenceGroupById(payload)
      return res?.data?.data || {}
    },
    async getGeoFenceSettingListByPage(context, payload) {
      const res = await getGeoFenceSettingListByPage(payload)
      this.commit('geoFence/setSettingList', res?.data?.data?.records || [])
      this.commit('geoFence/setSettingCount', res?.data?.data?.total || 0)
      return res
    },
    async handleNewGeoFenceGroupSetting(context, payload) {
      const res = await handleNewGeoFenceGroupSetting(payload)
      return res
    },
    async handleUpdateGeoFenceGroupSetting(context, payload) {
      const res = await handleUpdateGeoFenceGroupSetting(payload)
      return res
    },
    async handleDeleteGeoFenceGroupSetting(context, payload) {
      const res = await handleDeleteGeoFenceGroupSetting(payload)
      return res
    },
    async getGeoFenceGroupSettingById(context, payload) {
      const res = await getGeoFenceGroupSettingById(payload)
      return res?.data?.data || {}
    }
  }
}
