<template>
  <div class="file-upload">
    <div class="header">
      <p>{{ uploadFileListTitle }}</p>
      <hr />
    </div>
    <div class="upload-container">
      <div class="upload-flex">
        <div class="upload-title">{{ uploadFileListName }}</div>

        <div class="upload-content">
          <el-upload
            style="margin-left: 15px"
            class="upload-comp"
            ref="upload"
            :accept="acceptFileTypes"
            :file-list="fileList"
            :on-change="handleChange"
            :action="uploadURL"
            :show-file-list="true"
            :on-success="onSuccess"
            :on-error="onError"
            :on-remove="onRemove"
            :before-upload="handleBeforeUpload"
            :auto-upload="false"
          >
            <template v-slot:trigger>
              <el-button type="primary" size="mini">{{
                $t('general.select-file')
              }}</el-button>
            </template>

            <template v-slot:default>
              <!-- 上传列表默认内容 -->
            </template>
          </el-upload>
        </div>
      </div>
    </div>
    <div class="template-file" v-if="isShowTemplate">
      <a class="text_style" @click="handleDownloadFile" target="_blank">{{
        $t('general.template-files')
      }}</a>
    </div>
    <div class="btnHandler">
      <el-button
        type="primary"
        icon="el-icon-check"
        @click="submit"
        v-throttle
        size="small"
        >{{ $t('general.submit') }}</el-button
      >
      <el-button
        @click="reset"
        type="warning"
        icon="el-icon-refresh"
        size="small"
        >{{ $t('general.reset') }}</el-button
      >
      <el-button
        v-if="isShowBack"
        @click="back"
        icon="el-icon-back"
        plain
        size="small"
        >{{ $t('general.back') }}</el-button
      >

      <slot></slot>
    </div>
    <div class="error-msg" v-if="isShowMessage">
      <div class="header">
        <p>{{ $t(registerResultTitle) }}</p>
        <hr />
      </div>
      <el-input
        style="padding: 20px"
        v-model="resultMessage"
        :rows="4"
        type="textarea"
        disabled
      />
    </div>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { defineProps, nextTick, ref } from 'vue'
import { downloadFile } from '@/utils/util'
import i18n from '@/i18n'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps({
  acceptFileTypes: {
    type: String,
    default: '.csv'
  },
  uploadFileListTitle: {
    type: String,
    default: 'Upload File'
  },
  registerResultTitle: {
    type: String,
    default: 'general.register-result-t'
  },
  uploadFileListName: {
    type: String,
    default: 'Upload File List'
  },
  uploadURL: {
    type: String,
    required: true
  },
  templateURL: {
    type: String,
    default: ''
  },
  templateName: {
    type: String,
    default: ''
  },
  extensionName: {
    type: Array,
    default: () => ['csv']
  },
  fileSize: {
    type: Number,
    default: 1
  },
  isShowTemplate: {
    type: Boolean,
    default: true
  },
  isShowBack: {
    type: Boolean,
    default: false
  }
})

const { t } = i18n.global

const fileList = ref([])
const isShowMessage = ref(false)
const resultMessage = ref('')
const upload = ref(null)

const handleDownloadFile = () => {
  const fileUrl = props.templateURL
  const fileName = props.templateName
  downloadFile(fileUrl, fileName)
}

const handleChange = (file, lists) => {
  if (fileList.value.length > 0) upload.value.clearFiles()
  if (lists.length > 0) fileList.value = [lists[lists.length - 1]]
}

const onSuccess = (res) => {
  if (res?.code === 200) {
    ElMessage.success(t('general.upload-success-tip'))
  } else {
    ElMessage.error(t('general.upload-failed-tip'))
  }
  setTimeout(() => {
    isShowMessage.value = true
    console.log(res, 'onSuccess')
    resultMessage.value = res?.msg || ''
  }, 1000)
}

const onError = (res) => {
  ElMessage.error(t('general.upload-failed-tip'))
  fileList.value = []
  if (isShowMessage.value) isShowMessage.value = !isShowMessage.value
  console.log(res, 'onError')
  resultMessage.value = res?.msg || ''
}

const onRemove = () => {
  fileList.value = []
  if (isShowMessage.value) isShowMessage.value = !isShowMessage.value
  if (resultMessage.value !== '') resultMessage.value = ''
}

const handleBeforeUpload = (file) => {
  const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
  if (resultMessage.value !== '') {
    resultMessage.value = ''
    isShowMessage.value = false
  }
  const isTxt = props.extensionName.includes(extension)
  const isLimit = file.size / 1024 / 1024 < props.fileSize

  if (!isTxt) {
    const extensionName = props.extensionName.join('、')
    ElMessage.error(
      t('general.upload-file-format-type', { fileType: extensionName })
    )
  } else {
    if (!isLimit) {
      ElMessage.error(
        t('general.upload-file-limit-size', { fileSize: props.fileSize })
      )
    }
  }

  return isTxt && isLimit
}

const submit = () => {
  if (fileList.value.length <= 0)
    ElMessage.info(t('general.before-upload-validate-tip'))
  nextTick(() => {
    upload.value.submit()
  })
}

const reset = () => {
  if (resultMessage.value !== '') resultMessage.value = ''
  isShowMessage.value = false
  if (fileList.value.length > 0) {
    upload.value.clearFiles()
    fileList.value = []
  }
}

const back = () => {
  router.go(-1)
}
</script>

<style lang="scss">
.file-upload {
  border: 1px solid #e9e9eb;
  border-radius: 5px;
  padding-top: 22px;

  .header p {
    font-weight: bold;
    padding-left: 10px;
    font-size: 18px;
  }

  .header hr {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 98%;
    margin-left: 1%;
    color: #e9e9eb;
    background-color: #e9e9eb;
    height: 1px;
    border: none;
  }

  .upload-container {
    border: 1px solid #e9e9eb;
    margin: 10px;
    width: 94%;
    margin-left: 3%;
    height: 40px;
    margin-top: 20px;
    border-radius: 5px;

    .upload-flex {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      .upload-title {
        flex: 2;
        background-color: yellow;
        height: 100%;
        width: 100%;
        border-right: 1px solid #e9e9eb;
        background-color: #e8ecf1;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
      }

      .upload-content {
        flex: 14;
        .upload-comp {
          display: flex;
        }
      }
    }
  }

  .template-file {
    text-align: right;
    padding-right: 40px;

    .text_style {
      font-family: 'Microsoft YaHei UI', 'Helvetica Neue', Roboto, Arial,
        'Droid Sans', sans-serif;
      font-size: 13px;
      font-weight: 400;
      cursor: pointer;
      color: #337ab7;
    }
  }

  .btnHandler {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .error-msg {
    border: 1px solid #e9e9eb;
    margin: 15px;
    padding-top: 20px;
    border-radius: 5px;
  }
}

.upload-flex .el-list-enter-active,
.el-list-leave-active {
  transition: none;
}

.upload-flex .el-list-enter,
.upload-flex .el-list-leave-active {
  opacity: 0;
}
.upload-flex .el-upload-list {
  height: 40px;
}

.upload-flex .el-upload-list__item-name {
  color: #606266;
  display: block;
  margin-right: 40px;
  overflow: hidden;
  padding-left: 4px;
  text-overflow: ellipsis;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  white-space: nowrap;
  flex: 1;
  width: 560px;
}

.upload-flex .el-upload {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: 0;
  line-height: 40px;
  margin-left: 10px;
}
</style>
