/*****
 * @desc use to handle the context of user
 * @author cmt
 * @date 2023-4-13
 */
import {
  getCategoryLists,
  handleAddCategorySubmit,
  getCategoryListByID,
  deleteCategory,
  handleEditCategorySubmit
} from '../../service/category.js'
export default {
  namespaced: true,
  state: () => ({
    categoriesList: [],
    categoriesCount: 0
  }),
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    setCategoryList(state, categoriesList) {
      state.categoriesList = categoriesList
    },
    setCategoryCount(state, categoriesCount) {
      state.categoriesCount = categoriesCount
    }
  },
  actions: {
    async getCategoryList(context, payload) {
      const res = await getCategoryLists(payload)
      this.commit('category/setCategoryList', res?.data?.data?.records || [])
      this.commit('category/setCategoryCount', res?.data?.data?.total || 0)
      return res
    },
    async handleAddCategoryAction(context, payload) {
      const res = await handleAddCategorySubmit(payload)
      return res
    },
    async getCategoryListByID(context, payload) {
      const res = await getCategoryListByID(payload)
      return res
    },
    async handleDeleteCategory(context, payload) {
      const res = await deleteCategory(payload)
      return res
    },
    async handleEditCategoryAction(context, payload) {
      const res = await handleEditCategorySubmit(payload)
      return res
    }
  }
}
