import layout from '@/Layout/index.vue'

export default {
  path: '/home',
  component: layout,
  redirect: '/home',
  meta: {
    title: 'home'
  },
  children: [
    // user
    {
      path: '/user/overview',
      name: 'user',
      component: () => import('@/views/user/user/user-list/user.vue'),
      meta: {
        title: 'user',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-user-manage'
          }
        ]
      }
    },
    {
      path: '/users/add',
      name: 'userAdd',
      component: () => import('@/views/user/user/user-new/index.vue'),
      meta: {
        title: 'user-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/user/overview',
        parentTitle: [
          {
            title: 'app-user-manage'
          },
          {
            title: 'app-user-overview',
            path: '/user/overview'
          }
        ]
      }
    },
    {
      path: '/users/view/:id',
      name: 'userView',
      component: () => import('@/views/user/user/user-view/index.vue'),
      meta: {
        title: 'user-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/user/overview',
        parentTitle: [
          {
            title: 'app-user-manage'
          },
          {
            title: 'app-user-overview',
            path: '/user/overview'
          }
        ]
      }
    },
    {
      path: '/users/edit/:id',
      name: 'userEdit',
      component: () => import('@/views/user/user/user-edit/index.vue'),
      meta: {
        title: 'user-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/user/overview',
        parentTitle: [
          {
            title: 'app-user-manage'
          },
          {
            title: 'app-user-overview',
            path: '/user/overview'
          }
        ]
      }
    },
    {
      path: '/users/logs/:name',
      name: 'userLogs',
      component: () => import('@/views/user/user/user-log/index.vue'),
      meta: {
        title: 'user-log',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/user/overview',
        parentTitle: [
          {
            title: 'app-user-manage'
          },
          {
            title: 'app-user-overview',
            path: '/user/overview'
          }
        ]
      }
    },

    // role
    {
      path: '/user/role',
      name: 'role',
      component: () => import('@/views/user/role/role-list/index.vue'),
      meta: {
        title: 'role',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-user-manage'
          }
        ]
      }
    },
    {
      path: '/roles/new',
      name: 'roleNew',
      component: () => import('@/views/user/role/role-new/index.vue'),
      meta: {
        title: 'role-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/user/role',
        parentTitle: [
          {
            title: 'app-user-manage'
          },
          {
            title: 'app-role-overview',
            path: '/user/role'
          }
        ]
      }
    },
    {
      path: '/roles/edit/:id',
      name: 'roleEdit',
      component: () => import('@/views/user/role/role-edit/index.vue'),
      meta: {
        title: 'role-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/user/role',
        parentTitle: [
          {
            title: 'app-user-manage'
          },
          {
            title: 'app-role-overview',
            path: '/user/role'
          }
        ]
      }
    },
    {
      path: '/roles/view/:id',
      name: 'roleView',
      component: () => import('@/views/user/role/role-view/index.vue'),
      meta: {
        title: 'role-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/user/role',
        parentTitle: [
          {
            title: 'app-user-manage'
          },
          {
            title: 'app-role-overview',
            path: '/user/role'
          }
        ]
      }
    },
    // resource group
    {
      path: '/user/resource/group',
      name: 'resourceGroup',
      component: () =>
        import('@/views/user/resource-group/resource-list/index.vue'),
      meta: {
        title: 'resource-group',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-user-manage'
          }
        ]
      }
    },
    {
      path: '/groups/new',
      name: 'groupsNew',
      component: () =>
        import('@/views/user/resource-group/resource-new/index.vue'),
      meta: {
        title: 'resource-group-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/user/resource/group',
        parentTitle: [
          {
            title: 'app-user-manage'
          },
          {
            title: 'app-resource-overview',
            path: '/user/resource/group'
          }
        ]
      }
    },
    {
      path: '/groups/view/:id',
      name: 'groupsView',
      component: () =>
        import('@/views/user/resource-group/resource-view/index.vue'),
      meta: {
        title: 'resource-group-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/user/resource/group',
        parentTitle: [
          {
            title: 'app-user-manage'
          },
          {
            title: 'app-resource-overview',
            path: '/user/resource/group'
          }
        ]
      }
    },
    {
      path: '/groups/edit/:id',
      name: 'groupsEdit',
      component: () =>
        import('@/views/user/resource-group/resource-edit/index.vue'),
      meta: {
        title: 'resource-group-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/user/resource/group',
        parentTitle: [
          {
            title: 'app-user-manage'
          },
          {
            title: 'app-resource-overview',
            path: '/user/resource/group'
          }
        ]
      }
    }
  ]
}
