<template>
  <div>
    <svg-icon
      class="full-icon"
      :icon="isFullScreen ? 'exit-fullscreen' : 'fullscreen'"
      @click="onToggle"
      style="
        width: 20px;
        height: 20px;
        display: table-cell;
        vertical-align: middle;
      "
    ></svg-icon>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import screenFull from 'screenfull'

// 是否显示全屏
const isFullScreen = ref(false)

// 监听变化
const change = () => {
  isFullScreen.value = screenFull.isFullscreen
}

// 切换事件
const onToggle = () => {
  screenFull.toggle()
}

// 设置监听器
onMounted(() => {
  screenFull.on('change', change)
})

// 删除监听器
onUnmounted(() => {
  screenFull.off('change', change)
})
</script>

<style lang="scss" scoped>
.full-icon {
  width: 18px;
}
</style>
