import request from '../utils/request'
// import qs from 'qs'

export const getCustomerStatistics = (data) => {
  return request({
    url: '/rest/report/customer',
    data: data,
    method: 'post'
  })
}

export const getCustomerReportList = (data) => {
  return request({
    url: '/rest/customer/getPage',
    data: data,
    method: 'post'
  })
}

export const getApplicationStatistics = (data) => {
  return request({
    url: '/rest/report/app',
    data: data,
    method: 'post'
  })
}

export const getApplicationReportList = (data) => {
  return request({
    url: '/rest/terminalApp/getPage',
    data: data,
    method: 'post'
  })
}

export const getMerchantReportList = (data) => {
  return request({
    url: '/rest/merchant/getPage',
    data: data,
    method: 'post'
  })
}
export const getMerchantStatistics = (data) => {
  return request({
    url: '/rest/report/merchant',
    data: data,
    method: 'post'
  })
}
export const getTerminalReportList = (data) => {
  return request({
    url: '/rest/terminal/getPage',
    data: data,
    method: 'post'
  })
}
export const getTerminalStatistics = (data) => {
  return request({
    url: '/rest/report/terminal',
    data: data,
    method: 'post'
  })
}

export const downLoadCustomerReport = (data) => {
  return request({
    url: '/rest/easyExcel/customer',
    // data: qs.stringify(data),
    data,
    method: 'post',
    responseType: 'blob',
    transformResponse(data) {
      return new Blob([data])
    },
    otherOptions: {
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        accept: 'application/json'
      }
    }
  })
}

export const downLoadMerchantReport = (data) => {
  return request({
    url: '/rest/easyExcel/merchant',
    data,
    // data: qs.stringify(data),
    method: 'post',
    responseType: 'blob',
    transformResponse(data) {
      return new Blob([data])
    },
    otherOptions: {
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        accept: 'application/json'
      }
    }
  })
}

export const downLoadTerminalReport = (data) => {
  return request({
    url: '/rest/easyExcel/terminal',
    data: data,
    // data: qs.stringify(data),
    method: 'post',
    responseType: 'blob',
    transformResponse(data) {
      return new Blob([data])
    },
    otherOptions: {
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        accept: 'application/json'
      }
    }
  })
}

export const downLoadTerminalAppReport = (data) => {
  return request({
    url: '/rest/easyExcel/terminalApp',
    data,
    // data: qs.stringify(data),
    method: 'post',
    responseType: 'blob',
    transformResponse(data) {
      return new Blob([data])
    },
    otherOptions: {
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        accept: 'application/json'
      }
    }
  })
}
