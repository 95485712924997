/*****
 * @desc use to handle the context of user
 * @author mandy
 * @date 2023-4-28
 */
import {
  handleSendMessageSubmit,
  getReceiveMessageList,
  getSendMessageList,
  getSendMessageById,
  getReceiveMessageById,
  deleteReceiveMessageById,
  deleteSentMessageById,
  handleReadMessage,
  getDataDictionaryList,
  getDataDictionaryById,
  getDataDictionaryDetailById,
  handleEditDataDictionaryDetail,
  handleAddDataDictionaryDetail,
  deleteDataDictionaryDetailById,
  handleEditDataDictionary,
  handleAddDataDictionary,
  deleteDataDictionaryById,
  getDataDictionaryDetailList,
  getArticleList,
  getArticleDetailById,
  handleEditArticle,
  handleAddArticle,
  deleteArticleById,
  handleEnableArticle,
  handleDisableArticle,
  uploadImgFile,
  uploadFileType,
  handleEnableDataDictionaryDetail,
  handleDisableDataDictionaryDetail
} from '../../service/system.js'

export default {
  namespaced: true,
  state: () => ({
    receiveMessageList: [],
    receiveMessageCount: 0,
    sendMessageList: [],
    sendMessageCount: 0,
    dictList: [],
    dictCount: 0,
    articleList: [],
    articleCount: 0,
    imgPathList: {},
    currentFileList: {},
    tenantList: []
  }),
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    setTenantList(state, payload) {
      state.tenantList = payload
    },
    setFilePathList(state, payload) {
      state.currentFileList[payload.key] = payload.value
    },
    setImgPathList(state, payload) {
      state.imgPathList[payload.key] = payload.value
    },
    setReceiveMessageList(state, receiveMessageList) {
      state.receiveMessageList = receiveMessageList
    },
    setReceiveMessageCount(state, receiveMessageCount) {
      state.receiveMessageCount = receiveMessageCount
    },
    setSendMessageList(state, sendMessageList) {
      state.sendMessageList = sendMessageList
    },
    setSendMessageCount(state, sendMessageCount) {
      state.sendMessageCount = sendMessageCount
    },
    setDictionaryList(state, dictList) {
      state.dictList = dictList
    },
    setDictionaryCount(state, dictCount) {
      state.dictCount = dictCount
    },
    setDictionaryDetailList(state, dictDetailList) {
      state.dictDetailList = dictDetailList
    },
    setDictionaryDetailCount(state, dictDetailCount) {
      state.dictDetailCount = dictDetailCount
    },
    setArticleList(state, articleList) {
      state.articleList = articleList
    },
    setArticleCount(state, articleCount) {
      state.articleCount = articleCount
    }
  },
  actions: {
    async getReceiveMessageList(context, payload) {
      const res = await getReceiveMessageList(payload)
      this.commit(
        'system/setReceiveMessageList',
        res?.data?.data?.records || []
      )
      this.commit('system/setReceiveMessageCount', res?.data?.data?.total || 0)
      return res
    },
    async getSendMessageList(context, payload) {
      const res = await getSendMessageList(payload)
      this.commit('system/setSendMessageList', res?.data?.data?.records || [])
      this.commit('system/setSendMessageCount', res?.data?.data?.total || 0)
      return res
    },
    async handleSendMessageSubmit(context, payload) {
      const res = await handleSendMessageSubmit(payload)
      return res
    },
    async getSendMessageById(context, payload) {
      const res = await getSendMessageById(payload)
      return res?.data?.data || {}
    },
    async getReceiveMessageById(context, payload) {
      const res = await getReceiveMessageById(payload)
      return res?.data?.data || {}
    },
    async handleReadMessage(context, payload) {
      const res = await handleReadMessage(payload)
      return res
    },
    async deleteReceiveMessageById(context, payload) {
      const res = await deleteReceiveMessageById(payload)
      return res
    },
    async deleteSentMessageById(context, payload) {
      const res = await deleteSentMessageById(payload)
      return res
    },
    async getDataDictionaryList(context, payload) {
      const res = await getDataDictionaryList(payload)
      this.commit('system/setDictionaryList', res?.data?.data.records || [])
      this.commit('system/setDictionaryCount', res?.data?.data.total || 0)
      return res
    },
    async handleEditDataDictionary(context, payload) {
      const res = await handleEditDataDictionary(payload)
      return res
    },
    async handleAddDataDictionary(context, payload) {
      const res = await handleAddDataDictionary(payload)
      return res
    },
    async deleteDataDictionaryById(context, payload) {
      const res = await deleteDataDictionaryById(payload)
      return res
    },
    async getDataDictionaryById(context, payload) {
      const res = await getDataDictionaryById(payload)
      return res?.data?.data || {}
    },
    async getDataDictionaryDetailById(context, payload) {
      const res = await getDataDictionaryDetailById(payload)
      return res?.data?.data || {}
    },
    async handleEditDataDictionaryDetail(context, payload) {
      const res = await handleEditDataDictionaryDetail(payload)
      return res
    },
    async handleAddDataDictionaryDetail(context, payload) {
      const res = await handleAddDataDictionaryDetail(payload)
      return res
    },
    async deleteDataDictionaryDetailById(context, payload) {
      const res = await deleteDataDictionaryDetailById(payload)
      return res
    },
    async getDataDictionaryDetailList(context, payload) {
      const res = await getDataDictionaryDetailList(payload)
      this.commit(
        'system/setDictionaryDetailList',
        res?.data?.data.records || []
      )
      this.commit('system/setDictionaryDetailCount', res?.data?.data.total || 0)
      return res
    },
    async getArticleList(context, payload) {
      const res = await getArticleList(payload)
      this.commit('system/setArticleList', res?.data?.data?.records || [])
      this.commit('system/setArticleCount', res?.data?.data?.total || 0)
      return res
    },
    async getArticleDetailById(context, payload) {
      const res = await getArticleDetailById(payload)
      return res?.data?.data || {}
    },
    async handleEditArticle(context, payload) {
      const res = await handleEditArticle(payload)
      return res
    },
    async handleAddArticle(context, payload) {
      const res = await handleAddArticle(payload)
      return res
    },
    async deleteArticleById(context, payload) {
      const res = await deleteArticleById(payload)
      return res
    },
    async handleEnableArticle(context, payload) {
      const res = await handleEnableArticle(payload)
      return res
    },
    async handleDisableArticle(context, payload) {
      const res = await handleDisableArticle(payload)
      return res
    },
    async uploadImgFile(context, payload) {
      const res = await uploadImgFile(payload)
      return res?.data
    },
    async uploadFileType(context, payload) {
      const res = await uploadFileType(payload)
      return res?.data
    },
    async handleEnableDataDictionaryDetail(context, payload) {
      const res = await handleEnableDataDictionaryDetail(payload)
      return res
    },
    async handleDisableDataDictionaryDetail(context, payload) {
      const res = await handleDisableDataDictionaryDetail(payload)
      return res
    }
  }
}
