/*****
 * @desc use to handle the context of user
 * @author Mandy
 * @date 2023-4-25
 */
import { getHelpLists, getQuestionList, getAnswerList } from '@/service/help'

export default {
  namespaced: true,
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {
    async getHelpLists(context, payload) {
      const res = await getHelpLists(payload)
      return res?.data?.data || []
    },
    async getQuestionList(context, payload) {
      const res = await getQuestionList(payload)
      return res?.data?.data || []
    },
    async getAnswerList(context, payload) {
      const res = await getAnswerList(payload)
      console.log(res)
      return res?.data?.data || {}
    }
  }
}
