import {
  getDashboardStatistic,
  getTerminalDailyStatistics,
  getRecentUnreadMessage,
  getRecentOperators,
  getTerminalStatusStatistics
} from '@/service/dashboard'

export default {
  namespaced: true,
  state: () => ({
    customerCount: 0,
    customerRate: 0,
    merchantCount: 0,
    merchantRate: 0,
    terminalCount: 0,
    terminalRate: 0
  }),
  getters: {},
  mutations: {},
  actions: {
    async getDashboardStatistic(context, payload) {
      const res = await getDashboardStatistic(payload)
      return res?.data?.data || {}
    },
    async getTerminalDailyStatistics(context, payload) {
      const res = await getTerminalDailyStatistics(payload)
      return res?.data?.data || []
    },
    async getRecentUnreadMessage(context, payload) {
      const res = await getRecentUnreadMessage(payload)
      return res?.data?.data || {}
    },
    async getRecentOperators(context, payload) {
      const res = await getRecentOperators(payload)
      return res?.data?.data || {}
    },
    async getTerminalStatusStatistics(context, payload) {
      const res = await getTerminalStatusStatistics(payload)
      return res?.data?.data || {}
    }
  }
}
