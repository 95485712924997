/****
 * @desc use to storage data
 * @author Blank
 * @date 2022-10-18
 */
import { USERNAME, PASSWORD } from '@/utils/constant/index'

// 存储数据
export const setItem = (key, value) => {
  // 将对象 数组等类型转为JSON存储
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  window.sessionStorage.setItem(key, value)
}

// 获取数据
export const getItem = (key) => {
  const data = window.sessionStorage.getItem(key)
  try {
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}

// 删除数据
export const removeItem = (key) => {
  window.sessionStorage.removeItem(key)
}

// 清除数据
export const removeAllItem = () => {
  window.sessionStorage.clear()
}

export const removeAllExcludeItem = () => {
  // 需要保留记住密码功能 筛选掉不需要清除的缓存
  const keysToKeep = new Set([USERNAME, PASSWORD])
  const keysArr = window.sessionStorage
    ? Object.keys(window.sessionStorage)
    : []
  // 遍历所有sessionStorage项，清除不在keysToKeep中的键
  Array.from(keysArr).forEach((key) => {
    if (!keysToKeep.has(key)) {
      removeItem(key)
    }
  })
}
