import { createRouter, createWebHashHistory } from 'vue-router'
import { getItem } from '../utils/storage'
// import { handleUri } from '@/utils/util'
import { MENU_PERMISSION_LIST, ISLOGIN } from '@/utils/constant/index'
import { ElMessage } from 'element-plus'
import i18n from '@/i18n'

import fileRouter from './modules/file'
import monitorRouter from './modules/monitor'
import userRouter from './modules/user'
import paramRouter from './modules/param'
import logRouter from './modules/log'
import terminalRouter from './modules/terminal'
import reportRouter from './modules/report'
import profileRouter from './modules/system'
import helpRouter from './modules/help'
import tenantRouter from './modules/tenant'
import geoFenceRouter from './modules/geoFence'

import store from '@/store'

// 请用add表示添加页 edit表示编辑页 view表示查看
// searchFlag 设置路由是否允许被检索
// parentTitle 设置面包屑的标题及路径，不加路径默认跳转 /home
// activeMenu 设置跳转三级路由（例如编辑、查看等路由）时左侧高亮显示的对应菜单, 值为其父级二级菜单，同时用来判断三级路由的权限，三级菜单必传，否则路由守卫不通过
// isAuth 表示此路由是否需要进行权限控制

const publicRoutes = [
  {
    path: '/login',
    component: () => import('../views/login/login.vue'),
    name: 'login',
    meta: {
      isAuth: false
    }
  },
  {
    path: '/',
    component: () => import('../Layout/index.vue'),
    meta: {
      title: 'home',
      searchFlag: false,
      isAuth: false
    },
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'dashboard',
        component: () => import('../views/dashboard/dashboard.vue'),
        meta: {
          title: 'home',
          searchFlag: true,
          isAuth: false
        }
      }
    ]
  }
]

const privateRouter = [
  fileRouter,
  monitorRouter,
  userRouter,
  paramRouter,
  logRouter,
  terminalRouter,
  reportRouter,
  profileRouter,
  helpRouter,
  tenantRouter,
  geoFenceRouter
]
const router = createRouter({
  // 先暂时拼接公有 私有路由 后续通过vuex进行权限过滤
  routes: publicRoutes.concat(privateRouter),
  history: createWebHashHistory()
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // 判断是否已登录
  const isLogin = getItem(ISLOGIN)
  const newMenuList = getItem(MENU_PERMISSION_LIST) || []
  // const newMenuList = menuList.map((item) => {
  //   return item ? handleUri(item) : ''
  // })
  if (to.name !== 'login' && isLogin !== 'logged') {
    next({ name: 'login' })
  } else {
    // 当存在activeMenu时 表示该路由是子级路由 例如edit和view页面等，此类型路由判断其父级activeMenu即可
    if (to.meta.isAuth) {
      if (to.meta.activeMenu) {
        if (newMenuList.includes(to.meta.activeMenu)) {
          store.commit('globalParams/clearCacheObject')
          next()
        } else {
          ElMessage.error(i18n.global.t('general.error-router-tips'))
        }
      } else {
        if (newMenuList.includes(to.path)) {
          store.commit('globalParams/clearCacheObject')
          next()
        } else {
          ElMessage.error(i18n.global.t('general.error-router-tips'))
        }
      }
    } else {
      next()
    }
  }
})

export const setupRouter = (app) => {
  app.use(router)
}

export default router
