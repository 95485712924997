<template>
  <div class="footer-text">
    <div>
      Copyright © All Rights Reserved By
      <a href="http://www.wisecardtech.com" style="color: #23527c"
        >Wisecard Technology Co., Ltd.</a
      >
    </div>
    <!-- <div class="website-link">
      <a href="http://www.trustpaytech.com\"
        >Trust Payment Technology Co., Ltd.</a
      >
    </div> -->
  </div>
</template>
<script setup></script>
<style lang="scss">
.footer-text {
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.08);
  padding: 15px;
  text-align: center;
  font-size: 13px;
  .website-link {
    margin-top: 8px;
    cursor: pointer;
    color: #23527c;
  }
}
</style>
