/****
 * 判断是否为外部图标
 */
import i18n from '@/i18n'
import dayjs from 'dayjs'
import { getItem } from '@/utils/storage'

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export const userNameValidator = (rule, value, callback) => {
  if (value) {
    var reg = /^[A-Za-z_]+$/
    if (reg.test(value) === false) {
      callback(new Error(i18n.global.t('validator.name-validate')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// export const nameValidator = (rule, value, callback) => {
//   if (value) {
//     var reg = /^[a-zA-Z]{1}([a-zA-Z0-9]|[,._-]){0,99}$/
//     if (reg.test(value) === false) {
//       callback(new Error(i18n.global.t('validator.format-incorrect')))
//     } else {
//       callback()
//     }
//   } else {
//     callback()
//   }
// }

export const nameValidator = (rule, value, callback) => {
  if (value) {
    if (!/^[A-Za-z]/.test(value)) {
      callback(
        new Error(i18n.global.t('validator.user-name-begin-with-letters'))
      )
    } else if (value.length < 2 || value.length > 80) {
      callback(
        new Error(
          i18n.global.t('general.length-warnning-message', {
            min: 2,
            max: 80
          })
        )
      )
    } else if (!/^[A-Za-z][A-Za-z0-9_. @-]{2,80}$/.test(value)) {
      callback(new Error(i18n.global.t('validator.invalid-charts')))
    } else if (/[_.@-]{5,}/.test(value)) {
      callback(new Error(i18n.global.t('validator.too-much-special-charts')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const titleValidator = (rule, value, callback) => {
  if (value) {
    if (value.length < 2 || value.length > 100) {
      callback(
        new Error(
          i18n.global.t('general.length-warnning-message', {
            min: 2,
            max: 100
          })
        )
      )
    } else if (!/^[A-Za-z0-9~!@$^*, ._-]{2,100}$/.test(value)) {
      callback(new Error(i18n.global.t('validator.invalid-charts-example')))
    } else if (/[_.@-]{5,}/.test(value)) {
      callback(new Error(i18n.global.t('validator.too-much-special-charts')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 判断格式是否为1-9开头的整数，或者包含一位或多位小数位数不超过10位的数字
export const amountValidator = (rule, value, callback) => {
  if (value || value === 0) {
    var reg = /^[1-9][0-9]*(\.[0-9]{1,10})?$/
    if (reg.test(value) === false) {
      callback(new Error(i18n.global.t('validator.must-be-digit')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const phoneValidator = (rule, value, callback) => {
  if (value || value === 0) {
    var reg =
      /^([+]?0?\d{2,3}-?|\([+]?0?\d{2,3}\)|\([+]?0?\d{2,3}\))?\d+$|^([+]?0?\d{2,3}-?|\([+]?0?\d{2,3}\)|\([+]?0?\d{2,3}\))?[1-9]\d{4,10}(-\d{1,10})?$/
    if (reg.test(value) === false) {
      callback(new Error(i18n.global.t('validator.enter-right-phone')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 判断是否两位小数点的浮点数
export const floatAndPointValidator = (
  rule,
  value,
  callback,
  Maxvalue = 99999999999999.99,
  type = 1
) => {
  const MAX_VALUE = Math.floor(Maxvalue * 100) // 将最大值转换为整数
  const VALUE = Math.floor(value * 100) // 将输入值转换为整数
  const maxStringValue = '99999999999999.99'

  if (value || value === 0) {
    let reg
    if (type === 1) reg = /^([-+])?([0-9]+(\.[0-9]+)?|Infinity)$/
    else {
      reg = /^([+])?([0-9]+(\.[0-9]+)?|Infinity)$/
    }
    if (!reg.test(value)) {
      callback(new Error(i18n.global.t('validator.non-numeric-characters')))
    }

    if (VALUE > MAX_VALUE) {
      callback(
        new Error(
          i18n.global.t('validator.between-area-tip', {
            num: maxStringValue
          })
        )
      )
    }

    const pointRegex = /^-?\d+(.\d{1,2})?$/
    if (!pointRegex.test(value)) {
      callback(new Error(i18n.global.t('validator.keep-two-decimal-tip')))
    }

    callback()
  } else {
    callback()
  }
}

// 判断是否正整数
export const isIntegerValidator = (
  rule,
  value,
  callback,
  Maxvalue = 2147483647
) => {
  if (value || value === 0) {
    // 判断是否有非数组类型
    var reg = /^(?:[1-9]\d*)$/
    if (!reg.test(value)) {
      callback(new Error(i18n.global.t('validator.enter-positive-integer')))
    } else if (Number(value) > Number(Maxvalue)) {
      callback(
        new Error(
          i18n.global.t('validator.between-area-tip', { num: Maxvalue })
        )
      )
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const isLetterNumber = (rule, value, callback) => {
  if (value) {
    // 判断是否有非数组类型
    var reg = /^[A-Za-z0-9]+$/
    if (!reg.test(value)) {
      callback(new Error(i18n.global.t('validator.enter-letter-number')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const isNumber = (rule, value, callback) => {
  if (value || value === 0) {
    // 判断是否有非数组类型
    var reg = /^[0-9]+$/
    if (!reg.test(value)) {
      callback(new Error(i18n.global.t('validator.enter-number')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const isLetter = (rule, value, callback) => {
  if (value || value === 0) {
    // 判断是否有非数组类型
    var reg = /^[A-Za-z]+$/
    if (!reg.test(value)) {
      callback(new Error(i18n.global.t('validator.enter-letter')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const isSelectDayBeforeValidator = function (rule, value, callback) {
  if (value) {
    const currentTime = dayjs().valueOf()
    const selectTime = dayjs(value).valueOf()
    if (selectTime > currentTime) {
      callback(new Error(i18n.global.t('validator.no-later-than')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}
export const isSelectDayAfterValidator = function (rule, value, callback) {
  if (value) {
    const currentTime = dayjs().valueOf()
    const selectTime = dayjs(value).valueOf()
    if (selectTime < currentTime) {
      callback(new Error(i18n.global.t('validator.early-or-equal')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const isTotalHundredValidator = (rule, value, callback) => {
  const info = getItem('txnGroupPageInfo') || {}
  if (value && JSON.stringify(info) !== '{}') {
    const acquirerRate = (info.acquirerRate && parseInt(info.acquirerRate)) || 0
    const channelRate = (info.channelRate && parseInt(info.channelRate)) || 0
    const issuerRate = (info.issuerRate && parseInt(info.issuerRate)) || 0
    const receivingRate =
      (info.receivingRate && parseInt(info.receivingRate)) || 0
    if (issuerRate + acquirerRate + channelRate + receivingRate > 100) {
      callback(new Error(i18n.global.t('validator.must-less-than-100')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const isRate = (rule, value, callback) => {
  if (value) {
    // 判断是否有非数组类型
    var reg = /^(0.\d+|0|1)$/
    if (!reg.test(value)) {
      callback(new Error(i18n.global.t('validator.dccmarkup-rate-error-tip')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// validator中可以直接使用type: 'email'
export const isEmail = (rule, value, callback) => {
  if (value) {
    // 判断是否有非数组类型
    var reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    if (!reg.test(value)) {
      callback(new Error(i18n.global.t('validator.email-invalid-tip')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const isIP = (rule, value, callback) => {
  if (value) {
    // 判断是否有非数组类型
    var reg =
      /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    if (!reg.test(value)) {
      callback(new Error(i18n.global.t('validator.enter-ip')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const numberRangeValidator =
  (min = -Infinity, max = Infinity) =>
  (rule, value, callback) => {
    if (value) {
      var reg = /^[-]*[0-9]+$/
      if (!reg.test(value)) {
        callback(new Error(i18n.global.t('validator.enter-number')))
      } else {
        if (value >= min && value <= max) return callback()
        else
          return callback(
            new Error(
              i18n.global.t('validator.size-limit-message', {
                min,
                max
              })
            )
          )
      }
    } else {
      callback()
    }
  }

export const floatRangeValidator =
  (min = -Infinity, max = Infinity) =>
  (rule, value, callback) => {
    const isNumber = (val) => /^-?\d+(\.\d+)?$/.test(val)
    const isInRange = (val) => parseFloat(val) >= min && parseFloat(val) <= max
    const hasTwoDecimalPlaces = (val) => /^-?\d+(\.\d{1,2})?$/.test(val)

    if (value !== undefined && value !== null && value !== '') {
      if (!isNumber(value)) {
        callback(new Error(i18n.global.t('validator.enter-number')))
      } else if (!isInRange(value)) {
        callback(
          new Error(
            i18n.global.t('validator.size-limit-message', {
              min,
              max
            })
          )
        )
      } else if (!hasTwoDecimalPlaces(value)) {
        callback(new Error(i18n.global.t('validator.two-decimal-places')))
      } else if (value < 0) {
        // 添加负数校验条件
        callback(new Error(i18n.global.t('validator.not-negative-number')))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

export const timeValidator = (rule, value, callback) => {
  if (value) {
    var reg = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/
    if (!reg.test(value)) {
      callback(new Error('Please enter a valid time(00:00:00)!'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const codeStartValidator = (rule, value, callback) => {
  const info = getItem('vipNumberCardBinMap') || {}
  if (value && JSON.stringify(info) !== '{}') {
    if (
      parseInt(value) < parseInt(info.binNumStart) ||
      parseInt(value) > parseInt(info.binNumEnd)
    ) {
      callback(
        new Error(
          i18n.global.t('validator.size-limit-message', {
            min: info.binNumStart,
            max: info.binNumEnd
          })
        )
      )
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const codeEndValidator = (rule, value, callback) => {
  const info = getItem('vipNumberCardBinMap') || {}
  if (value && JSON.stringify(info) !== '{}') {
    if (
      parseInt(value) < parseInt(info.binCardNoStart) ||
      parseInt(value) > parseInt(info.binCardNoEnd)
    ) {
      callback(
        new Error(
          i18n.global.t('validator.size-limit-message', {
            min: info.binCardNoStart,
            max: info.binCardNoEnd
          })
        )
      )
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const floatAndPointValidate = (rule, value, callback, type = 1) => {
  if (!value) {
    callback()
    return
  }

  const isNumberRegex = /^-?\d+(\.\d+)?$/
  const decimalRegex = /^-?\d+(\.\d{1,2})?$/
  const maxValue = 99999999999999.99
  const minValue = 0
  const maxStringValue = '99999999999999.99'
  const floatValue = parseFloat(value)

  if (isNaN(floatValue) || !isNumberRegex.test(value)) {
    callback(new Error(i18n.global.t('validator.enter-number')))
  } else if (floatValue > maxValue) {
    callback(
      new Error(
        i18n.global.t('validator.size-limit-message', {
          max: maxStringValue,
          min: minValue
        })
      )
    )
  } else if (floatValue < 0 && type === 1) {
    // 添加负数校验条件
    callback(new Error(i18n.global.t('validator.not-negative-number')))
  } else if (!decimalRegex.test(value)) {
    callback(new Error(i18n.global.t('validator.two-decimal-places')))
  } else {
    callback()
  }
}

export const isAmountFormatValid = (
  rule,
  value,
  callback,
  Maxvalue = 99999999999999.99
) => {
  if (value || value === 0) {
    // 检查是否包含非数字字符，包括逗号
    var reg = /^([-+])?([0-9]+(,[0-9]+)*(\.[0-9]+)?|Infinity)$/
    if (!reg.test(value)) {
      callback(new Error(i18n.global.t('validator.non-numeric-characters')))
    }
    // 检查值是否超过最大允许值
    if (Number(value.replace(/,/g, '')) > Number(Maxvalue)) {
      callback(
        new Error(
          i18n.global.t('validator.between-area-tip', { num: Maxvalue })
        )
      )
    }

    // 检查是否保留两位小数
    var pointRegex = /^-?\d{1,3}(,\d{3})*(\.\d{2})?$/
    if (!pointRegex.test(value)) {
      callback(new Error(i18n.global.t('validator.keep-two-decimal-tip')))
    }
    callback()
  } else {
    callback()
  }
}
