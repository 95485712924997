import request from '../utils/request'

// 获取类别列表
export const getCategoryLists = (data) => {
  return request({
    url: `/rest/category/getByPage/${data.currentPage}/${data.pageCount}`,
    data: data,
    method: 'post'
  })
}

// 添加类别
export const handleAddCategorySubmit = (data) => {
  return request({
    url: '/rest/category/add',
    data,
    method: 'post'
  })
}

// 查看类别详情
export const getCategoryListByID = (id) => {
  return request({
    url: '/rest/category/getById',
    data: id,
    method: 'post'
  })
}

// 删除类别
export const deleteCategory = (id) => {
  return request({
    url: `/rest/category/del/${id}`,
    method: 'delete'
  })
}

// 编辑类别
export const handleEditCategorySubmit = (data) => {
  return request({
    url: `/rest/category/update`,
    data,
    method: 'post'
  })
}
