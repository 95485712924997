import { createI18n } from 'vue-i18n'
import mZhLocale from './locales/zh-cn.json'
import mEnLocale from './locales/en.json'
import store from '@/store'

// 创建数据源
const messages = {
  en: {
    ...mEnLocale
  },
  zh: {
    ...mZhLocale
  }
}

// 定义变量控制环境
// const locale = 'en'
// 获取当前的语言环境
function getLanguage() {
  return store && store.getters && store.getters.language
}

const i18n = createI18n({
  globalInjection: true,
  legacy: false, // composition api模式下 需要置为false
  locale: getLanguage(),
  fallbackLocale: 'en', // 设置默认的降级语言
  messages
})

export const setupI18n = (app) => {
  app.use(i18n)
}

export default i18n
