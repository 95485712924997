<template>
  <div class="progess-modal-wrapper">
    <div class="modal-container">
      <el-dialog
        :title="$t('general.warm-tip-title')"
        v-model="dialogVisible"
        width="25%"
        class="centered-dialog"
      >
        <el-progress type="circle" :percentage="p"></el-progress>
        <template v-slot:footer>
          <span class="dialog-footer">
            {{ $t('general.download-report-status') }}
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineExpose } from 'vue'
import { ElMessage } from 'element-plus'

const dialogVisible = ref(false)
const time = 1000
const p = ref(0)

const randomPercent = Math.floor(Math.random() * (80 - 60 + 1) + 70)

const handleInitalTimerAnimate = () => {
  const t = Math.ceil(time / randomPercent)
  const initalTimer = setInterval(() => {
    p.value += 1

    if (p.value >= randomPercent) {
      p.value = randomPercent
      clearInterval(initalTimer)
    }
  }, t)
}

const handleRerenderInitialAnimate = () => {
  const t = Math.ceil(time / (100 - randomPercent))
  const rerenderTimer = setInterval(() => {
    p.value += 1
    if (p.value >= 100) {
      p.value = 100
      clearInterval(rerenderTimer)
      setTimeout(() => {
        ElMessage.success('Download successfully!')
        dialogVisible.value = false
      }, 1000)
    }
  }, t)
}

watch(dialogVisible, (newVal, oldVal) => {
  if (newVal) {
    handleInitalTimerAnimate()
  }
  if (!newVal && oldVal) {
    // 关闭模态框时 销毁阶段
    p.value = 0
  }
})

const handleOpenDialog = () => {
  dialogVisible.value = true
}

const closeDialog = () => {
  dialogVisible.value = false
}

defineExpose({ handleRerenderInitialAnimate, handleOpenDialog, closeDialog })
</script>

<style>
.progess-modal-wrapper .el-dialog__footer {
  padding: 10px 20px 20px;
  text-align: center !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.progess-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  text-align: center;
}

.centered-dialog {
  max-width: 100%;
  width: auto;
}
</style>
