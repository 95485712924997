<template>
  <router-view> </router-view>
</template>

<script setup>
import { useStore } from 'vuex'
import { generateNewStyle, writeNewStyle } from '@/utils/theme'

const store = useStore()
// 根据Store中存储的主题色进行生成
generateNewStyle(store.getters.mainColor).then((newStyleText) => {
  writeNewStyle(newStyleText)
})
</script>

<style lang="scss">
@import '~@/styles/variables.scss';
</style>
