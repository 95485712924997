import { createApp } from 'vue'
import { setupI18n } from './i18n'
import App from './app.vue'
import { setupRouter } from './router'
import { setupStore } from '@/store/index'
import { registerElementPlus } from './plugins/element'
import Directives from '@/directives/index'
import { registerProperties } from '@/plugins/globalProperties'
import globalComponents from '@/plugins/globalComponents'
import './styles/index.scss'
import './styles/css/font-awesome.min.css'
import { registerGlobIcons } from '@/icons'
import { setItem } from '@/utils/storage'
// import { setupErrorHandle } from '@/error-handler'
import vue3TreeOrg from 'vue3-tree-org'
import 'vue3-tree-org/lib/vue3-tree-org.css'
// import JsonViewer from 'vue-json-viewer'
// import JsonViewer from 'vue3-json-viewer'
import 'vue3-json-viewer/dist/index.css' // 引入样式

import {
  ISLOGIN,
  BTN_PERMISSION_LIST,
  MENU_PERMISSION_LIST
} from '@/utils/constant/index'

// 防止手动修改本地存储
window.addEventListener('storage', (e) => {
  if (
    e.key === BTN_PERMISSION_LIST ||
    e.key === MENU_PERMISSION_LIST ||
    e.key === ISLOGIN
  ) {
    setItem(e.key, e.oldValue)
  }
})

async function bootstrap() {
  const app = createApp(App)

  // 配置store
  setupStore(app)

  // 初始化内部系统配置（主题 颜色以及其他个性化）
  // initAppConfigStore()

  registerElementPlus(app)

  // 注册全局的样式图标
  registerGlobIcons(app)

  // 注册全局组件
  // registerComponents(app)

  registerProperties(app)

  // 多语言配置 (语言文件可能从服务端拉取)
  await setupI18n(app)

  // 配置路由
  setupRouter(app)

  // 配置全局错误处理
  // setupErrorHandle(app)
  app.use(globalComponents)
  app.use(vue3TreeOrg)
  app.use(Directives)
  // app.use(JsonViewer)
  app.mount('#app')
}

// 调用函数 开始应用的初始化
bootstrap()
