<template>
  <!-- 一级 menu 菜单 -->
  <el-menu
    :uniqueOpened="true"
    :default-active="activeMenu"
    :background-color="$store.getters.cssVar.menuBg"
    :text-color="$store.getters.cssVar.menuText"
    :active-text-color="$store.getters.cssVar.menuActiveText"
    :collapse="!$store.getters.sidebarOpened"
    router
  >
    <!-- 子集 menu 菜单 -->
    <sidebar-item v-for="item in routes" :key="item.uri" :route="item">
    </sidebar-item>
  </el-menu>
</template>

<script setup>
import { ref, getCurrentInstance, computed } from 'vue'
import SidebarItem from '@/Layout/components/SideBar/SidebarItem'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'

const routes = ref([])
// 获取菜单信息
const store = useStore()
const route = useRoute()

const cxt = getCurrentInstance()
const handleGlobalLogout = cxt.appContext.config.globalProperties.$handleLogout

const getMenuInfo = async () => {
  const data = await store.dispatch('user/getMenuInfo')
  const menuList = data?.resource || []

  //  获取不到菜单或没有菜单时直接跳转到登录页面
  if (!menuList.length) {
    ElMessage.error('Some issues have occurred, please login again')
    handleGlobalLogout(false)
    return
  }

  routes.value = menuList
}
getMenuInfo()

// 计算高亮menu的方法
const activeMenu = computed(() => {
  const { meta, path } = route

  // 如果跳转的路由有activeMenu的话，就让这activeMenu保持高亮，一般为父级菜单，如果没有则让跳转的页面高亮

  if (meta.activeMenu) {
    return meta.activeMenu
  }
  return path
})
</script>
