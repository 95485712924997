/*****
 * @desc use to handle the context of user
 * @author Blank
 * @date 2022-10-18
 */
import { getCustomerById } from '../../service/tenant.js'
import { setItem, getItem } from '@/utils/storage'
import { RESOURCEMENUBTNLIST } from '@/utils/constant/index'
import {
  login,
  getUserInfo,
  getMenuInfo,
  getUserList,
  getUserListByPage,
  getTerminalList,
  logoutSystem,
  getSessionTimeout,
  getCustomerList,
  getMerchantList,
  getRoleList,
  getRoleListByPage,
  handleAddUserSubmit,
  getUserByName,
  getUserDetailById,
  handleEditUserSubmit,
  deleteUserById,
  getUserLogs,
  getGroupList,
  getGroupListByPage,
  handleAddRoleSubmit,
  getCurrentRoleList,
  handleEditRoleSubmit,
  deleteRoleById,
  getResourceMenuBtnList,
  getResourceGroupById,
  getBtnListById,
  handleEditResourceSubmit,
  handleAddResourceSubmit,
  handleDeleteResourceSubmit,
  updateProfileInfo,
  changePassword,
  resetPassword,
  getPasswordExpiry,
  getPasswordExpiryDay,
  getPermissionBtnList,
  getPermissionMenuList,
  handleDisableUser,
  handleEnableUser,
  handleResetPassword
} from '../../service/user.js'

export default {
  namespaced: true,
  state: () => ({
    userInfo: {},
    usersCount: 0,
    usersList: [],
    allUserList: [],
    terminalCount: 0,
    terminalList: [],
    roleList: [], // 所有角色列表
    rolesList: [], // 当前页角色列表
    rolesCount: 0,
    userLogsList: [],
    userLogsCount: 0,
    resourcesList: [],
    resourcesCount: 0,
    resuorceMenuBtnList: getItem(RESOURCEMENUBTNLIST) || []
  }),
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setUserCount(state, usersCount) {
      state.usersCount = usersCount
    },
    setUserList(state, usersList) {
      state.usersList = usersList
    },
    setAllUserList(state, allUserList) {
      state.allUserList = allUserList
    },
    setTerminalCount(state, terminalCount) {
      state.terminalCount = terminalCount
    },
    setTerminalList(state, terminalList) {
      state.terminalList = terminalList
    },
    setRolePageList(state, rolesList) {
      state.rolesList = rolesList
    },
    setRolePageCount(state, rolesCount) {
      state.rolesCount = rolesCount
    },
    setUserLogsList(state, userLogsList) {
      state.userLogsList = userLogsList
    },
    setUserLogsCount(state, userLogsCount) {
      state.userLogsCount = userLogsCount
    },
    setResourceGroupList(state, resourcesList) {
      state.resourcesList = resourcesList
    },
    setResourceGroupCount(state, resourcesCount) {
      state.resourcesCount = resourcesCount
    },
    setResuorceMenuBtn(state, resuorceMenuBtnList) {
      state.resuorceMenuBtnList = resuorceMenuBtnList
    }
  },
  actions: {
    login(ctx, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async getUserInfo(context) {
      const res = await getUserInfo()
      setItem('userInfo', res?.data?.data || {})
      this.commit('user/setUserInfo', res.data)
      return res
    },
    async getMenuInfo(context) {
      const res = await getMenuInfo()
      return res?.data?.data || {}
    },
    async getUserList(context) {
      const res = await getUserList()
      this.commit('user/setAllUserList', res?.data?.data || [])
      return res
    },
    async getUserListByPage(context, payload) {
      const res = await getUserListByPage(payload)
      this.commit('user/setUserList', res?.data?.data?.records || [])
      this.commit('user/setUserCount', res?.data?.data?.total || 0)
      return res
    },
    async getTerminalList(context, payload) {
      const res = await getTerminalList(payload)
      this.commit('user/setTerminalList', res?.data?.data?.records || [])
      this.commit('user/setTerminalCount', res?.data?.data?.total || 0)
      return res
    },
    async handleLogout(context, payload) {
      const res = await logoutSystem()
      return res
    },
    async handleResetPassword(context, payload) {
      const res = await handleResetPassword(payload)
      return res
    },
    async handleIsSessionTimeout(context, payload) {
      const res = await getSessionTimeout(payload)
      return res
    },
    async getCustomerList(context, payload) {
      const res = await getCustomerList(payload)
      setItem('customerList', res?.data || [])
      return res?.data || []
    },
    async getCustomerById(context, payload) {
      const res = await getCustomerById(payload)
      return res
    },
    async getMerchantList(context, payload) {
      const res = await getMerchantList(payload)
      setItem('merchantList', res?.data?.data || [])
      return res?.data?.data || []
    },
    async getMerchantListByTenantId(context, payload) {
      const res = await getMerchantList(payload)
      return res?.data?.data || []
    },
    // 获取所有角色列表
    async getRoleList(context, payload) {
      const res = await getRoleList(payload)
      setItem('roleList', res?.data?.data || [])
      return res?.data?.data || []
    },
    // 获取分页角色列表
    async getRoleListByPage(context, payload) {
      const res = await getRoleListByPage(payload)
      this.commit('user/setRolePageList', res?.data?.data?.records || [])
      this.commit('user/setRolePageCount', res?.data?.data?.total || 0)
      return res
    },
    async handleAddUserAction(context, payload) {
      const res = await handleAddUserSubmit(payload)
      return res
    },
    async handleGetUser(context, payload) {
      const res = await getUserByName(payload)
      return res?.data || {}
    },
    async getUserDetailById(context, payload) {
      const res = await getUserDetailById(payload)
      return res?.data?.data || {}
    },
    async handleEditUserAction(context, payload) {
      const res = await handleEditUserSubmit(payload)
      return res
    },
    async handleDisableUser(context, payload) {
      const res = await handleDisableUser(payload)
      return res
    },
    async handleEnableUser(context, payload) {
      const res = await handleEnableUser(payload)
      return res
    },
    async handleDeleteUser(context, payload) {
      const res = await deleteUserById(payload)
      return res
    },
    async getUserLogsList({ dispatch }, payload) {
      const res = await getUserLogs(payload)
      this.commit('user/setUserLogsList', res?.data?.data?.records || [])
      this.commit('user/setUserLogsCount', res?.data?.data?.total || 0)
      return res
    },
    async getGroupList(context, payload) {
      const res = await getGroupList()
      return res?.data?.data || []
    },
    async handleAddRoleAction(context, payload) {
      const res = await handleAddRoleSubmit(payload)
      return res
    },
    async getCurrentRoleList(context, payload) {
      const res = await getCurrentRoleList(payload)
      return res?.data?.data || {}
    },
    async handleEditRoleAction(context, payload) {
      const res = await handleEditRoleSubmit(payload)
      return res
    },
    async handleDeleteRole({ dispatch }, payload) {
      const res = await deleteRoleById(payload)
      return res
    },
    async getResourceGroupList(context, payload) {
      const res = await getGroupListByPage(payload)
      this.commit('user/setResourceGroupList', res?.data?.data?.records || [])
      this.commit('user/setResourceGroupCount', res?.data?.data?.total || 0)
      return res
    },
    // 返回的资源菜单包含按钮
    async getResourceMenuBtnList(context, payload) {
      const res = await getResourceMenuBtnList()
      this.commit('user/setResuorceMenuBtn', res?.data?.data || [])
      setItem(RESOURCEMENUBTNLIST, context.state.resuorceMenuBtnList)
      return res?.data?.data || []
    },
    async getResourceGroupById(context, payload) {
      const res = await getResourceGroupById(payload)
      return res?.data?.data || {}
    },
    async getBtnListById(context, payload) {
      const res = await getBtnListById(payload)
      return res
    },
    async getPermissionBtnList(context, payload) {
      const res = await getPermissionBtnList(payload)
      return res?.data?.data || []
    },
    async getPermissionMenuList(context, payload) {
      const res = await getPermissionMenuList(payload)
      return res?.data?.data || []
    },
    async handleEditResourceAction(context, payload) {
      const res = await handleEditResourceSubmit(payload)
      return res
    },
    async handleAddResourceAction(context, payload) {
      const res = await handleAddResourceSubmit(payload)
      return res
    },
    async handleDeleteResourceAction(context, payload) {
      const res = await handleDeleteResourceSubmit(payload)
      return res
    },
    // My profile 更新、重置密码、获取密码expiry
    async handleUpdateProfile(context, payload) {
      const res = await updateProfileInfo(payload)
      return res
    },
    async changePassword(context, payload) {
      const res = await changePassword(payload)
      return res
    },
    async resetPassword(context, payload) {
      const res = await resetPassword(payload)
      return res
    },
    async getPasswordExpiry(context, payload) {
      const res = await getPasswordExpiry(payload)
      return res
    },
    async getPasswordExpiryDay(context, payload) {
      const res = await getPasswordExpiryDay(payload)
      return res?.data?.data || 0
    }
  }
}
