import request from '../utils/request'

export const getHelpLists = () => {
  return request({
    url: '/rest/article/questionType',
    method: 'get'
  })
}

export const getQuestionList = (type) => {
  return request({
    url: `/rest/article/${type}`,
    method: 'get'
  })
}

export const getAnswerList = (data) => {
  return request({
    url: `/rest/article/${data.type}/${data.title}`,
    method: 'get'
  })
}
