import layout from '@/Layout/index.vue'

export default {
  path: '/home',
  component: layout,
  redirect: '/home',
  meta: {
    title: 'home'
  },
  children: [
    {
      path: '/tenant/customer',
      name: 'Customers',
      component: () =>
        import('@/views/tenant/customer/customer-list/customers.vue'),
      meta: {
        title: 'customer-overview',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-tenant'
          }
        ]
      }
    },
    {
      path: '/customer/new',
      name: 'CustomerNew',
      component: () =>
        import('@/views/tenant/customer/customer-handle/customer-handle.vue'),
      meta: {
        title: 'customer-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/tenant/customer',
        parentTitle: [
          {
            title: 'app-tenant'
          },
          {
            title: 'app-customer-overview',
            path: '/tenant/customer'
          }
        ]
      }
    },
    {
      path: '/customer/view/:id',
      name: 'CustomerView',
      component: () =>
        import('@/views/tenant/customer/customer-handle/customer-handle.vue'),
      meta: {
        title: 'customer-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/tenant/customer',
        parentTitle: [
          {
            title: 'app-customer'
          },
          {
            title: 'app-customer-overview',
            path: '/tenant/customer'
          }
        ]
      }
    },
    {
      path: '/customer/edit/:id',
      name: 'CustomerEdit',
      component: () =>
        import('@/views/tenant/customer/customer-handle/customer-handle.vue'),
      meta: {
        title: 'customer-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/tenant/customer',
        parentTitle: [
          {
            title: 'app-tenant'
          },
          {
            title: 'app-customer-overview',
            path: '/tenant/customer'
          }
        ]
      }
    },
    {
      path: '/tenant/merchant',
      name: 'Merchants',
      component: () =>
        import('@/views/tenant/merchant/merchant-list/merchants.vue'),
      meta: {
        title: 'merchant-overview',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-tenant'
          }
        ]
      }
    },
    {
      path: '/merchant/new',
      name: 'MerchantNew',
      component: () =>
        import('@/views/tenant/merchant/merchant-handle/merchant-handle.vue'),
      meta: {
        title: 'merchant-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/tenant/merchant',
        parentTitle: [
          {
            title: 'app-tenant'
          },
          {
            title: 'app-merchant-overview',
            path: '/tenant/merchant'
          }
        ]
      }
    },
    {
      path: '/merchant/view/:id',
      name: 'MerchantView',
      component: () =>
        import('@/views/tenant/merchant/merchant-handle/merchant-handle.vue'),
      meta: {
        title: 'merchant-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/tenant/merchant',
        parentTitle: [
          {
            title: 'app-tenant'
          },
          {
            title: 'app-merchant-overview',
            path: '/tenant/merchant'
          }
        ]
      }
    },
    {
      path: '/merchant/edit/:id',
      name: 'MerchantEdit',
      component: () =>
        import('@/views/tenant/merchant/merchant-handle/merchant-handle.vue'),
      meta: {
        title: 'merchant-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/tenant/merchant',
        parentTitle: [
          {
            title: 'app-tenant'
          },
          {
            title: 'app-merchant-overview',
            path: '/tenant/merchant'
          }
        ]
      }
    },
    {
      path: '/tenant/overview',
      name: 'Tenant',
      component: () => import('@/views/tenant/tenant/tenant-list/index.vue'),
      meta: {
        title: 'tenant-overview',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-tenant'
          }
        ]
      }
    },
    {
      path: '/tenant/new',
      name: 'TenantNew',
      component: () => import('@/views/tenant/tenant/tenant-handle/index.vue'),
      meta: {
        title: 'tenant-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/tenant/overview',
        parentTitle: [
          { title: 'app-tenant' },
          {
            title: 'app-tenant-overview',
            path: '/tenant/overview'
          }
        ]
      }
    },
    {
      path: '/tenant/edit/:id',
      name: 'TenantEdit',
      component: () => import('@/views/tenant/tenant/tenant-handle/index.vue'),
      meta: {
        title: 'tenant-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/tenant/overview',
        parentTitle: [
          { title: 'app-tenant' },
          {
            title: 'app-tenant-overview',
            path: '/tenant/overview'
          }
        ]
      }
    },
    {
      path: '/tenant/view/:id',
      name: 'TenantView',
      component: () => import('@/views/tenant/tenant/tenant-handle/index.vue'),
      meta: {
        title: 'tenant-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/tenant/overview',
        parentTitle: [
          { title: 'app-tenant' },
          {
            title: 'app-tenant-overview',
            path: '/tenant/overview'
          }
        ]
      }
    }
  ]
}
