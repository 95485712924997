/*****
 * @desc use to handle the context of user
 * @author Blank
 * @date 2022-11-30
 */
import {
  getApplicationById,
  getApplicationLists,
  getApplicationTypeList,
  handleApplicationSubmit,
  handleImportApplicationFile,
  handleApplicationEdit,
  handlePublishApplicationAction,
  handleApplicationDisableAction,
  handleApplicationEnableAction,
  handleDisableApplicationAction,
  handleEnableApplicationAction,
  handlePushApplicationAction,
  getStoreListData,
  deleteApplicationById,
  getApplicationFileLists,
  deleteApplicationFile,
  getStore,
  updateStore,
  downLoadApp,
  countDownload,
  getOtaLists,
  getOtaById,
  getOtaStatusList,
  handleOtaSubmit,
  deleteOtaById,
  handleUpdateOta,
  handlePublishOtaAction,
  handleReleaseOtaAction,
  handleDisableOtaFileAction,
  handleEnableOtaFileAction,
  handleDisableOtaAction,
  handleEnableOtaAction,
  handleImportOtaFile,
  getOtaCount,
  getOtaFileLists,
  deleteOtaFileById,
  getWelcomePage,
  getWelcomeDetailByID,
  deleteWelcomeByID,
  handleUpdateWelcomePage,
  handleAddWelcomePage,
  handleDisableWelcomePageAction,
  handleEnableWelcomePageAction
} from '../../service/file'

export default {
  namespaced: true,
  state: () => ({
    applicationsCount: 0,
    applicationsList: [],
    applicationFileCount: 0,
    applicationFileList: [],
    currentApplication: {},
    storesCount: 0,
    storesList: [],
    embeddedAppsList: [],
    embeddedAppsCount: 0,
    otaCount: 0,
    otaList: [],
    OtaValueTypeList: [],
    apphomeList: [],
    apphomeCount: 0,
    otaFileCount: 0,
    otaFileList: []
  }),
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    setApplicationsCount(state, applicationsCount) {
      state.applicationsCount = applicationsCount
    },
    setApplicationsList(state, applicationsList) {
      state.applicationsList = applicationsList
    },
    setApplicationFileCount(state, applicationFileCount) {
      state.applicationFileCount = applicationFileCount
    },
    setApplicationFileList(state, applicationFileList) {
      state.applicationFileList = applicationFileList
    },
    setCurrentApplication(state, application) {
      state.currentApplication = application
    },
    setStoreList(state, storesList) {
      state.storesList = storesList
    },
    setStoreCount(state, storesCount) {
      state.storesCount = storesCount
    },
    setEmbeddedCount(state, embeddedCount) {
      state.embeddedAppsCount = embeddedCount
    },
    setEmbeddedList(state, embeddedList) {
      state.embeddedAppsList = embeddedList
    },
    setOtasCount(state, otaCount) {
      state.otaCount = otaCount
    },
    setOtasList(state, otaList) {
      state.otaList = otaList
    },
    setOtaFileCount(state, otaFileCount) {
      state.otaFileCount = otaFileCount
    },
    setOtaFileList(state, otaFileList) {
      state.otaFileList = otaFileList
    },
    setOtaValueTypeList(state, OtaValueTypeList) {
      state.OtaValueTypeList = OtaValueTypeList
    },
    setAppHomeList(state, apphomeList) {
      state.apphomeList = apphomeList
    },
    setAppHomeCount(state, apphomeCount) {
      state.apphomeCount = apphomeCount
    }
  },
  actions: {
    async handleApplicationList(context, payload) {
      const res = await getApplicationLists(payload)

      this.commit('file/setApplicationsList', res?.data?.data?.records || [])
      this.commit('file/setApplicationsCount', res?.data?.data?.total || 0)
      return res
    },
    async getApplicationFileLists(context, payload) {
      const res = await getApplicationFileLists(payload)
      this.commit('file/setApplicationFileList', res?.data?.data?.records || [])
      this.commit('file/setApplicationFileCount', res?.data?.data?.total || 0)
      return res
    },
    async getApplicationList(context, payload = {}) {
      const res = await getApplicationTypeList(payload)
      return res?.data || []
    },
    async handleApplicationAction(context, payload) {
      const res = await handleApplicationSubmit(payload)
      return res
    },
    async handleImportApplicationFile(context, payload) {
      const res = await handleImportApplicationFile(payload)
      return res
    },
    async handleDisableApplicationAction(ctx, payload) {
      const res = await handleDisableApplicationAction(payload)
      return res
    },
    async handleEnableApplicationAction(ctx, payload) {
      const res = await handleEnableApplicationAction(payload)
      return res
    },
    async handlePushApplicationAction(ctx, payload) {
      const res = await handlePushApplicationAction(payload)
      return res
    },
    async handleApplicationEdit(ctx, payload) {
      const res = await handleApplicationEdit(payload)
      return res
    },
    async handlePublishApplicationAction({ dispatch }, payload) {
      const res = await handlePublishApplicationAction(payload)
      return res
    },
    async handleApplicationEnableAction({ dispatch }, payload) {
      const res = await handleApplicationEnableAction(payload)
      return res
    },
    async handleApplicationDisableAction({ dispatch }, payload) {
      const res = await handleApplicationDisableAction(payload)
      return res
    },
    async handleGetApplication(context, payload) {
      const res = await getApplicationById(payload)
      // 防止res.data中没有第一项 添加默认值
      this.commit('file/setCurrentApplication', res.data[0] || {})
      return res?.data?.data || {}
    },
    async deleteApplicationFile(ctx, payload) {
      const res = await deleteApplicationFile(payload)
      return res
    },
    async handleGetStoreList(context, payload = {}) {
      const res = await getStoreListData(payload)
      this.commit('file/setStoreList', res?.data?.data?.records || [])
      this.commit('file/setStoreCount', res?.data?.data?.total || 0)
      return res
    },
    async handleDeleteApplication({ dispatch }, payload) {
      const res = await deleteApplicationById(payload)
      return res
    },
    async updateStore(context, payload) {
      const res = await updateStore(payload)
      return res
    },
    async getStore(context, payload) {
      const res = await getStore(payload)
      return res
    },
    async downLoadApp(context, payload) {
      const res = await downLoadApp(payload)
      return res
    },
    async countDownload(context, payload) {
      const res = await countDownload(payload)
      return res
    },
    async getOtaListData(context, payload) {
      const res = await getOtaLists(payload)
      this.commit('file/setOtasList', res?.data?.data?.records || [])
      this.commit('file/setOtasCount', res?.data?.data?.total || 0)
      return res
    },
    async getOtaOptionList(context, payload = {}) {
      const resStatus = await getOtaStatusList(payload)
      const resultStatus = Object.keys(resStatus.data).map((i) => {
        return {
          id: i,
          name: resStatus.data[i]
        }
      })
      return resultStatus || []
    },
    async handleOtaAction(context, payload) {
      const res = await handleOtaSubmit(payload)
      return res
    },
    async getOtaById(context, payload) {
      const res = await getOtaById(payload)
      return res?.data?.data || {}
    },
    async handleImportOtaFile(context, payload) {
      const res = await handleImportOtaFile(payload)
      return res
    },
    async handleDeleteOta({ dispatch }, payload) {
      const res = await deleteOtaById(payload)
      return res
    },
    async handleUpdateOta({ dispatch }, payload) {
      const res = await handleUpdateOta(payload)
      return res
    },
    async handlePublishOtaAction({ dispatch }, payload) {
      const res = await handlePublishOtaAction(payload)
      return res
    },
    async handleReleaseOtaAction({ dispatch }, payload) {
      const res = await handleReleaseOtaAction(payload)
      return res
    },
    async handleEnableOtaFileAction({ dispatch }, payload) {
      const res = await handleEnableOtaFileAction(payload)
      return res
    },
    async handleDisableOtaFileAction({ dispatch }, payload) {
      const res = await handleDisableOtaFileAction(payload)
      return res
    },
    async handleDisableOtaAction({ dispatch }, payload) {
      const res = await handleDisableOtaAction(payload)
      return res
    },
    async handleEnableOtaAction({ dispatch }, payload) {
      const res = await handleEnableOtaAction(payload)
      return res
    },
    async getOtaCount({ dispatch }, payload) {
      const res = await getOtaCount(payload)
      return res
    },
    async getOtaFileLists(context, payload) {
      const res = await getOtaFileLists(payload)
      this.commit('file/setOtaFileList', res?.data?.data?.records || [])
      this.commit('file/setOtaFileCount', res?.data?.data?.total || 0)
      return res
    },
    async deleteOtaFileById({ dispatch }, payload) {
      const res = await deleteOtaFileById(payload)
      return res
    },
    async getWelcomePage(context, payload) {
      const res = await getWelcomePage(payload)
      this.commit('file/setAppHomeList', res?.data?.data?.records || [])
      this.commit('file/setAppHomeCount', res?.data?.data?.total || 0)
      return res
    },
    async getWelcomeDetailByID({ dispatch }, payload) {
      const res = await getWelcomeDetailByID(payload)
      return res?.data?.data || {}
    },
    async deleteWelcomeByID({ dispatch }, payload) {
      const res = await deleteWelcomeByID(payload)
      return res
    },
    async handleUpdateWelcomePage({ dispatch }, payload) {
      const res = await handleUpdateWelcomePage(payload)
      return res
    },
    async handleAddWelcomePage({ dispatch }, payload) {
      const res = await handleAddWelcomePage(payload)
      return res
    },
    async handleDisableWelcomePageAction({ dispatch }, payload) {
      const res = await handleDisableWelcomePageAction(payload)
      return res
    },
    async handleEnableWelcomePageAction({ dispatch }, payload) {
      const res = await handleEnableWelcomePageAction(payload)
      return res
    }
  }
}
