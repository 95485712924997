import layout from '@/Layout/index.vue'

export default {
  path: '/home',
  component: layout,
  redirect: '/home',
  meta: {
    title: 'home'
  },
  children: [
    {
      path: '/system/message',
      name: 'Message',
      component: () => import('@/views/system/message/index.vue'),
      meta: {
        title: 'my-messages',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-my'
          }
        ]
      }
    },
    {
      path: '/system/message/new',
      name: 'newMessage',
      component: () =>
        import('@/views/system/sendMessage/message-handle/index.vue'),
      meta: {
        title: 'message-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/system/message',
        parentTitle: [
          {
            title: 'app-my'
          },
          {
            title: 'app-my-messages',
            path: '/system/message'
          }
        ]
      }
    },

    {
      path: '/system/message/view/:id',
      name: 'viewMessage',
      component: () =>
        import('@/views/system/sendMessage/message-handle/index.vue'),
      meta: {
        title: 'message-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/system/message',
        parentTitle: [
          {
            title: 'app-my'
          },
          {
            title: 'app-my-messages',
            path: '/system/message'
          }
        ]
      }
    },
    {
      path: '/system/message/readmessages/:id',
      name: 'readMessage',
      component: () => import('@/views/system/inbox/message-view/index.vue'),
      meta: {
        title: 'message-read',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/system/message',
        parentTitle: [
          {
            title: 'app-my'
          },
          {
            title: 'app-my-messages',
            path: '/system/message'
          }
        ]
      }
    },
    {
      path: '/system/profile',
      name: 'MyProfile',
      component: () => import('@/views/system/profile/profile.vue'),
      meta: {
        title: 'my-profile',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-my'
          }
        ]
      }
    },
    {
      path: '/system/profile/changePassword',
      name: 'changePassword',
      component: () => import('@/views/system/profile/changePassword.vue'),
      meta: {
        title: 'change-password',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/system/profile',
        parentTitle: [
          {
            title: 'app-my'
          },
          {
            title: 'app-my-profiles'
          }
        ]
      }
    },
    {
      path: '/system/article',
      name: 'Article',
      component: () => import('@/views/system/article/article-list/index.vue'),
      meta: {
        title: 'article-center',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-my'
          }
        ]
      }
    },
    {
      path: '/system/article/view/:id',
      name: 'ArticleView',
      component: () =>
        import('@/views/system/article/article-handle/index.vue'),
      meta: {
        title: 'article-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/system/article',
        parentTitle: [
          {
            title: 'app-my'
          },
          {
            title: 'app-article-center',
            path: '/system/article'
          }
        ]
      }
    },
    {
      path: '/system/article/edit/:id',
      name: 'ArticleEdit',
      component: () =>
        import('@/views/system/article/article-handle/index.vue'),
      meta: {
        title: 'article-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/system/article',
        parentTitle: [
          {
            title: 'app-my'
          },
          {
            title: 'app-article-center',
            path: '/system/article'
          }
        ]
      }
    },
    {
      path: '/system/article/new',
      name: 'ArticleNew',
      component: () =>
        import('@/views/system/article/article-handle/index.vue'),
      meta: {
        title: 'article-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/system/article',
        parentTitle: [
          {
            title: 'app-my'
          },
          {
            title: 'app-article-center',
            path: '/system/article'
          }
        ]
      }
    },
    {
      path: '/system/dict',
      name: 'DataDictionary',
      component: () =>
        import('@/views/system/dataDictionary/dataDictionary-list/index.vue'),
      meta: {
        title: 'data-dictionary',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-my'
          }
        ]
      }
    },
    {
      path: '/system/dict/detail/:name/:id',
      name: 'DataDictionaryDetail',
      component: () =>
        import('@/views/system/dataDictionary/dataDictionary-detail/index.vue'),
      meta: {
        title: 'data-dictionary-detail',
        searchFlag: true,
        activeMenu: '/system/dict',
        isAuth: true,
        parentTitle: [
          {
            title: 'app-my'
          },
          {
            title: 'app-data-dictionary',
            path: '/system/dict'
          }
        ]
      }
    },
    {
      path: '/system/dict/view/:id',
      name: 'DataDictionaryViews',
      component: () =>
        import('@/views/system/dataDictionary/dataDictionary-handle/index.vue'),
      meta: {
        title: 'data-dictionary-view',
        searchFlag: true,
        activeMenu: '/system/dict',
        isAuth: true,
        parentTitle: [
          {
            title: 'app-my'
          },
          {
            title: 'app-data-dictionary',
            path: '/system/dict'
          }
        ]
      }
    },
    {
      path: '/system/dict/edit/:id',
      name: 'DataDictionaryEdit',
      component: () =>
        import('@/views/system/dataDictionary/dataDictionary-handle/index.vue'),
      meta: {
        title: 'data-dictionary-edit',
        searchFlag: true,
        activeMenu: '/system/dict',
        isAuth: true,
        parentTitle: [
          {
            title: 'app-my'
          },
          {
            title: 'app-data-dictionary',
            path: '/system/dict'
          }
        ]
      }
    },
    {
      path: '/system/dict/new',
      name: 'DataDictionaryNew',
      component: () =>
        import('@/views/system/dataDictionary/dataDictionary-handle/index.vue'),
      meta: {
        title: 'data-dictionary-new',
        searchFlag: true,
        activeMenu: '/system/dict',
        isAuth: true,
        parentTitle: [
          {
            title: 'app-my'
          },
          {
            title: 'app-data-dictionary',
            path: '/system/dict'
          }
        ]
      }
    }
  ]
}
