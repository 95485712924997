import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
// 导入elment-plus语言包
// import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import en from 'element-plus/lib/locale/lang/en'
// import store from '@/store'

export const registerElementPlus = (app) => {
  app.use(ElementPlus)
}
