<template>
  <div class="navbar">
    <hamburger class="hamburger-container" />
    <breadcrumb class="breadcrumb-container" />
    <div class="right-menu">
      <span style="margin-right: 14px; color: #737478"
        >Hi,{{ userInfo?.userName || 'User' }}</span
      >
      <!-- 全局模糊检索 -->
      <header-search class="header-search" />
      <!-- 导入国际化语言切换 -->
      <!-- <lang-select
        class="right-menu-item hover-effect"
        style="margin-left: 5px"
      /> -->
      <!-- <role-change
        class="right-menu-item hover-effect"
        style="margin-left: 5px"
      /> -->
      <theme-select
        class="theme-select"
        effect="light"
        style="margin-left: 2px"
      />
      <Screenful
        class="right-menu-item hover-effect"
        style="margin-left: 3px"
      />
      <!-- 头像 -->
      <el-dropdown
        class="avatar-container"
        trigger="click"
        style="margin-left: 5px"
      >
        <div class="avatar-wrapper">
          <el-avatar shape="square" :size="30" :src="avatar"> </el-avatar>
          <!-- <em class="el-icon-s-tools"></em> -->
        </div>
        <template #dropdown>
          <el-dropdown-item
            v-if="newMenuList.includes('/system/profile')"
            @click="goToProfile"
          >
            {{ $t('common.app-profile') }}
          </el-dropdown-item>
          <el-dropdown-item
            v-if="newMenuList.includes('/article/help')"
            divided
            @click="goToHelp"
          >
            {{ $t('common.app-help') }}
          </el-dropdown-item>
          <el-dropdown-item divided @click="handleLogout">
            {{ $t('common.app-login-out') }}
          </el-dropdown-item>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import avatar from '@/assets/imgs/timg.jpg'
import Hamburger from '@/components/Hamburger'
import Breadcrumb from '@/components/Breadcrumb'
// import LangSelect from '@/components/LangSelect'
import Screenful from '@/components/Screenful'
import ThemeSelect from '@/components/ThemeSelect/index.vue'
import HeaderSearch from '@/components/headerSearch/index.vue'
// import RoleChange from '@/components/RoleChange/index.vue'
import { MENU_PERMISSION_LIST } from '@/utils/constant/index'
import { getItem } from '@/utils/storage'

const store = useStore()
const userInfo = ref(null)
const router = useRouter()
const cxt = getCurrentInstance()
const handleGlobalLogout = cxt.appContext.config.globalProperties.$handleLogout
const newMenuList = getItem(MENU_PERMISSION_LIST) || []

const initialUserInfo = async () => {
  const res = await store.dispatch('user/getUserInfo')
  const data = res?.data?.data || {}
  userInfo.value = data
}
initialUserInfo()

const handleLogout = async () => {
  await handleGlobalLogout()
}

const goToHelp = async () => {
  router.push('/article/help')
}
const goToProfile = async () => {
  router.push('/system/profile')
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.08);
  .header-search {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .breadcrumb-container {
    float: left;
    line-height: 46px;
  }

  .right-menu {
    display: flex;
    align-items: center;
    float: right;
    padding-right: 16px;
    margin-top: 5px;

    :deep(.right-menu-item) {
      width: 18px;
      display: inline-block;
      padding: 0 18px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
      }
    }
    .theme-select {
      margin-right: 15px;
    }

    :deep(.avatar-container) {
      cursor: pointer;
      .avatar-wrapper {
        display: flex;
        align-items: center;
        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
        .el-icon-s-tools {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
