<template>
  <el-dialog
    :title="$t('general.tips')"
    :model-value="modelValue"
    @close="closed"
    width="22%"
  >
    <div class="center">
      <p class="title">{{ $t('general.theme-color-select') }}</p>
      <el-color-picker
        v-model="mColor"
        :predefine="predefineColors"
      ></el-color-picker>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="reset" plain>{{ $t('general.reset') }}</el-button>
        <el-button @click="closed">{{ $t('general.cancel') }}</el-button>
        <el-button type="primary" @click="confirm">{{
          $t('general.confirm')
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import { useStore } from 'vuex'
import { generateNewStyle, writeNewStyle } from '@/utils/theme'
import { menuBg } from '@/styles/variables.scss'

defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
})
const emits = defineEmits(['update:modelValue'])
const store = useStore()
// 预定义色值
const predefineColors = [
  '#5b5e69',
  '#1b0d19',
  '#386997',
  '#152333',
  'rgb(47, 47, 119)',
  '#2592ac',
  '#314841',
  '#93451d',
  'rgb(30, 83, 149)',
  '#021837'
  // 'hsv(51, 100, 98)',
  // 'hsva(120, 40, 94, 0.5)',
  // 'hsl(181, 100%, 37%)',
  // 'hsla(209, 100%, 56%, 0.73)',
]
// 默认色值
// const mainColor = store.getters.mainColor

const mColor = ref('')

mColor.value = store.getters.mainColor
/**
 * 关闭
 */
const closed = () => {
  emits('update:modelValue', false)
  setTimeout(() => {
    mColor.value = store.getters.mainColor
  }, 1000)
}
/**
 * 确定
 * 1. 修改主题色
 * 2. 保存最新的主题色
 * 3. 关闭 dialog
 */
const confirm = async () => {
  // 1.1 获取主题色
  const newStyleText = await generateNewStyle(mColor.value)
  // 1.2 写入最新主题色
  writeNewStyle(newStyleText)
  // 2. 保存最新的主题色
  store.dispatch('app/updateThemeColor', { themeColor: mColor.value })
  // 3. 关闭 dialog
  closed()
}
const reset = async () => {
  // 1.1 重置主题色
  mColor.value = menuBg
  const newStyleText = await generateNewStyle(mColor.value)

  // 1.2 写入最初主题色
  writeNewStyle(newStyleText)
  // 2. 保存最初的主题色
  store.dispatch('app/updateThemeColor', { themeColor: mColor.value })
  // 3. 关闭 dialog
  closed()
}
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
  .title {
    margin-bottom: 12px;
  }
}
</style>
