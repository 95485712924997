import request from '../utils/request'

// message 提交按钮
export const handleSendMessageSubmit = (data) => {
  return request({
    url: '/rest/message/add',
    data,
    method: 'post'
  })
}

// TODO 有问题
// message 获取表格列表
export const getReceiveMessageList = (data) => {
  return request({
    url: `/rest/message/receive`,
    data,
    method: 'post'
  })
}

export const getSendMessageList = (data) => {
  return request({
    url: `/rest/message/sent`,
    data,
    method: 'post'
  })
}

// 获取message详情
export const getSendMessageById = (id) => {
  return request({
    url: `/rest/message/sent/${id}`,
    method: 'get'
  })
}

export const getReceiveMessageById = (id) => {
  return request({
    url: `/rest/message/receive/${id}`,
    method: 'get'
  })
}

export const deleteReceiveMessageById = (id) => {
  return request({
    url: `/rest/message/receive/del/${id}`,
    method: 'delete'
  })
}

export const deleteSentMessageById = (id) => {
  return request({
    url: `/rest/message/sent/del/${id}`,
    method: 'delete'
  })
}

export const handleReadMessage = (data) => {
  return request({
    url: '/rest/message/read',
    data,
    method: 'get'
  })
}

export const getDataDictionaryList = (data) => {
  return request({
    url: `/rest/dictType/getPage`,
    data,
    method: 'post'
  })
}

export const getDataDictionaryById = (data) => {
  return request({
    url: `/rest/dictType/get/${data.id}`,
    method: 'get'
  })
}

export const handleEditDataDictionary = (data) => {
  return request({
    url: `/rest/dictType/edit`,
    data,
    method: 'put'
  })
}

export const handleAddDataDictionary = (data) => {
  return request({
    url: `/rest/dictType/add`,
    data,
    method: 'post'
  })
}

export const deleteDataDictionaryById = (id) => {
  return request({
    url: `/rest/dictType/del/${id}`,
    method: 'delete'
  })
}

export const getDataDictionaryDetailList = (data) => {
  return request({
    url: `/rest/dict/getPage`,
    data,
    method: 'post'
  })
}

export const getDataDictionaryDetailById = (data) => {
  return request({
    url: `/rest/dict/get/${data.id}`,
    method: 'get'
  })
}

export const handleEditDataDictionaryDetail = (data) => {
  return request({
    url: `/rest/dict/edit`,
    data,
    method: 'put'
  })
}

export const handleAddDataDictionaryDetail = (data) => {
  return request({
    url: `/rest/dict/add`,
    data,
    method: 'post'
  })
}

export const handleEnableDataDictionaryDetail = (id) => {
  return request({
    url: `/rest/dict/enable/${id}`,
    method: 'put'
  })
}

export const handleDisableDataDictionaryDetail = (id) => {
  return request({
    url: `/rest/dict/disable/${id}`,
    method: 'put'
  })
}

export const deleteDataDictionaryDetailById = (id) => {
  return request({
    url: `/rest/dict/del/${id}`,
    method: 'delete'
  })
}

export const getArticleList = (data) => {
  return request({
    url: `/rest/article/getPage`,
    data,
    method: 'post'
  })
}

export const getArticleDetailById = (data) => {
  return request({
    url: `/rest/article/get/${data.id}`,
    method: 'get'
  })
}

export const handleEditArticle = (data) => {
  return request({
    url: `/rest/article/edit`,
    data,
    method: 'put'
  })
}

export const handleAddArticle = (data) => {
  return request({
    url: `/rest/article/add`,
    data,
    method: 'post'
  })
}

export const deleteArticleById = (id) => {
  return request({
    url: `/rest/article/del/${id}`,
    method: 'delete'
  })
}

export const handleEnableArticle = (id) => {
  return request({
    url: `/rest/article/enable/${id}`,
    method: 'put'
  })
}

export const handleDisableArticle = (id) => {
  return request({
    url: `/rest/article/disable/${id}`,
    method: 'put'
  })
}

// 文件上传返回url
export const uploadImgFile = (data) => {
  return request({
    url: '/rest/upload/image',
    method: 'post',
    data
  })
}

export const uploadFileType = ({ data, type }) => {
  return request({
    url: `/rest/upload/${type}`,
    method: 'post',
    data,
    otherOptions: {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  })
}
