import layout from '@/Layout/index.vue'

export default {
  path: '/home',
  component: layout,
  redirect: '/home',
  meta: {
    title: 'home'
  },
  children: [
    {
      path: '/article/about',
      name: 'About',
      component: () => import('@/views/help/about/index.vue'),
      meta: {
        title: 'about',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-help-center'
          }
        ]
      }
    },
    {
      path: '/article/help',
      name: 'Help',
      component: () => import('@/views/help/online-help/index.vue'),
      meta: {
        title: 'online-help',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-help-center'
          }
        ]
      }
    },
    {
      path: '/article/question/:type/:title',
      name: 'Question',
      component: () => import('@/views/help/online-help/question.vue'),
      meta: {
        title: 'help-question',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/article/help',
        parentTitle: [
          {
            title: 'app-help-center'
          },
          {
            title: 'app-online-help',
            path: '/article/help'
          }
        ]
      }
    },
    {
      path: '/article/answer/:type/:title',
      name: 'Answer',
      component: () => import('@/views/help/online-help/answer.vue'),
      meta: {
        title: 'help-answer',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/article/help',
        parentTitle: [
          {
            title: 'app-help-center'
          },
          {
            title: 'app-online-help',
            path: '/article/help'
          }
        ]
      }
    }
  ]
}
