import layout from '@/Layout/index.vue'

export default {
  path: '/home',
  component: layout,
  redirect: '/home',
  meta: {
    title: 'home'
  },
  children: [
    {
      path: '/geo-fence/overview',
      name: 'geoFence',
      component: () =>
        import('@/views/geoFence/geoFence/geoFence-list/index.vue'),
      meta: {
        title: 'geo-fence',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-geo-fence-main'
          }
        ]
      }
    },
    {
      path: '/geo-fence/edit/:id',
      name: 'geoFenceEdit',
      component: () =>
        import('@/views/geoFence/geoFence/geoFence-handle/index.vue'),
      meta: {
        title: 'geo-fence-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/geo-fence/overview',
        parentTitle: [
          {
            title: 'app-geo-fence-main'
          },
          {
            title: 'app-geo-fence-overview',
            path: '/geo-fence/overview'
          }
        ]
      }
    },
    {
      path: '/geo-fence/view/:id',
      name: 'geoFenceView',
      component: () =>
        import('@/views/geoFence/geoFence/geoFence-handle/index.vue'),
      meta: {
        title: 'geo-fence-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/geo-fence/overview',
        parentTitle: [
          {
            title: 'app-geo-fence-main'
          },
          {
            title: 'app-geo-fence-overview',
            path: '/geo-fence/overview'
          }
        ]
      }
    },
    {
      path: '/geo-fence/new',
      name: 'geoFenceNew',
      component: () =>
        import('@/views/geoFence/geoFence/geoFence-handle/index.vue'),
      meta: {
        title: 'geo-fence-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/geo-fence/overview',
        parentTitle: [
          {
            title: 'app-geo-fence-main'
          },
          {
            title: 'app-geo-fence-overview',
            path: '/geo-fence/overview'
          }
        ]
      }
    },
    {
      path: '/geo-fence/group',
      name: 'geoFenceGroup',
      component: () =>
        import('@/views/geoFence/geoFenceGroup/geoFenceGroup-list/index.vue'),
      meta: {
        title: 'geo-fence-group',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-geo-fence-main'
          }
        ]
      }
    },
    {
      path: '/geo-fence/group/edit/:id',
      name: 'geoFenceGroupEdit',
      component: () =>
        import('@/views/geoFence/geoFenceGroup/geoFenceGroup-handle/index.vue'),
      meta: {
        title: 'geo-fence-group-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/geo-fence/group',
        parentTitle: [
          {
            title: 'app-geo-fence-main'
          },
          {
            title: 'app-geo-fence-group',
            path: '/geo-fence/group'
          }
        ]
      }
    },
    {
      path: '/geo-fence/group/view/:id',
      name: 'geoFenceGroupView',
      component: () =>
        import('@/views/geoFence/geoFenceGroup/geoFenceGroup-handle/index.vue'),
      meta: {
        title: 'geo-fence-group-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/geo-fence/group',
        parentTitle: [
          {
            title: 'app-geo-fence-main'
          },
          {
            title: 'app-geo-fence-group',
            path: '/geo-fence/group'
          }
        ]
      }
    },
    {
      path: '/geo-fence/group/new',
      name: 'geoFenceGroupNew',
      component: () =>
        import('@/views/geoFence/geoFenceGroup/geoFenceGroup-handle/index.vue'),
      meta: {
        title: 'geo-fence-group-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/geo-fence/group',
        parentTitle: [
          {
            title: 'app-geo-fence-main'
          },
          {
            title: 'app-geo-fence-group',
            path: '/geo-fence/group'
          }
        ]
      }
    },
    {
      path: '/geo-fence/setting',
      name: 'geoFenceSetting',
      component: () =>
        import(
          '@/views/geoFence/geoFenceSetting/geoFenceSetting-list/index.vue'
        ),
      meta: {
        title: 'geo-fence-setting',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-geo-fence-main'
          }
        ]
      }
    },
    {
      path: '/geo-fence/setting/edit/:id',
      name: 'geoFenceSettingEdit',
      component: () =>
        import(
          '@/views/geoFence/geoFenceSetting/geoFenceSetting-handle/index.vue'
        ),
      meta: {
        title: 'geo-fence-setting-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/geo-fence/setting',
        parentTitle: [
          {
            title: 'app-geo-fence-main'
          },
          {
            title: 'app-geo-fence-setting',
            path: '/geo-fence/setting'
          }
        ]
      }
    },
    {
      path: '/geo-fence/setting/view/:id',
      name: 'geoFenceSettingView',
      component: () =>
        import(
          '@/views/geoFence/geoFenceSetting/geoFenceSetting-handle/index.vue'
        ),
      meta: {
        title: 'geo-fence-setting-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/geo-fence/setting',
        parentTitle: [
          {
            title: 'app-geo-fence-main'
          },
          {
            title: 'app-geo-fence-setting',
            path: '/geo-fence/setting'
          }
        ]
      }
    },
    {
      path: '/geo-fence/setting/new',
      name: 'geoFenceSettingNew',
      component: () =>
        import(
          '@/views/geoFence/geoFenceSetting/geoFenceSetting-handle/index.vue'
        ),
      meta: {
        title: 'geo-fence-setting-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/geo-fence/setting',
        parentTitle: [
          {
            title: 'app-geo-fence-main'
          },
          {
            title: 'app-geo-fence-setting',
            path: '/geo-fence/setting'
          }
        ]
      }
    }
  ]
}
