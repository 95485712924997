import request from '../utils/request'
// import qs from 'qs'
export const getOperationLists = (data) => {
  return request({
    url: '/rest/log/getPage',
    data: data,
    method: 'post'
  })
}

export const deleteSelectOperation = (data) => {
  return request({
    url: `/rest/admin/logs/delsel`,
    data: data,
    method: 'post'
  })
}
export const deleteSelectAudit = (data) => {
  return request({
    url: `/rest/terminal/tracelogs/delsel`,
    data: data,
    method: 'post'
  })
}
export const deleteSelectTrace = (data) => {
  return request({
    url: `/rest/terminal/auditlogs/delsel`,
    data: data,
    method: 'post'
  })
}
export const getAuditLists = (data) => {
  return request({
    url: `/rest/terminalLog/operation`,
    data: data,
    method: 'post'
  })
}
export const getTraceLists = (data) => {
  return request({
    url: `/rest/terminalLog/trace`,
    data,
    method: 'post'
  })
}

export const getTerminalLogLists = (data) => {
  return request({
    url: `/rest/terminalLog/getPage`,
    data: data,
    method: 'post'
  })
}
