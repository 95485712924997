import {
  getCustomerStatistics,
  getCustomerReportList,
  getApplicationStatistics,
  getApplicationReportList,
  getMerchantReportList,
  getMerchantStatistics,
  getTerminalReportList,
  getTerminalStatistics,
  downLoadCustomerReport,
  downLoadMerchantReport,
  downLoadTerminalReport,
  downLoadTerminalAppReport
} from '../../service/report'

export default {
  namespaced: true,
  state: () => ({
    customerXLabels: [],
    customerReportValues: [],
    customerReportCount: 0,
    customerReportList: [],
    applicationXLabels: [],
    applicationReportValues: [],
    applicationReportCount: 0,
    applicationReportList: [],
    merchantXLabels: [],
    merchantReportValues: [],
    merchantReportCount: 0,
    merchantReportList: [],
    terminalXLabels: [],
    terminalReportValues: [],
    terminalReportCount: 0,
    terminalReportList: []
  }),
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    // =================
    setCustomerXLabels(state, payload) {
      state.customerXLabels = payload
    },
    setCustomerValues(state, payload) {
      state.customerReportValues = payload
    },
    setApplicationLabels(state, payload) {
      state.applicationXLabels = payload
    },
    setApplicationValues(state, payload) {
      state.applicationReportValues = payload
    },
    setCustomerReportCount(state, payload) {
      state.customerReportCount = payload
    },
    setCustomerReportList(state, payload) {
      state.customerReportList = payload
    },
    // ===============
    setApplicationReportCount(state, payload) {
      state.applicationReportCount = payload
    },
    setApplicationReportList(state, payload) {
      state.applicationReportList = payload
    },
    // ===================
    setMerchantXLabels(state, payload) {
      state.merchantXLabels = payload
    },
    setMerchantValues(state, payload) {
      state.merchantReportValues = payload
    },
    setMerchantReportCount(state, payload) {
      state.merchantReportCount = payload
    },
    setMerchantReportList(state, payload) {
      state.merchantReportList = payload
    },
    // ===================
    setTerminalXLabels(state, payload) {
      state.terminalXLabels = payload
    },
    setTerminalValues(state, payload) {
      state.terminalReportValues = payload
    },
    setTerminalReportCount(state, payload) {
      state.terminalReportCount = payload
    },
    setTerminalReportList(state, payload) {
      state.terminalReportList = payload
    }
  },
  actions: {
    async handleCustomerReportList({ commit }, payload) {
      const res = await getCustomerStatistics(payload)
      const customerXLabels = []
      const customerReportValues = []
      const list = res?.data?.data || []
      list.forEach((item) => {
        customerXLabels.push(item?.tenantName)
        customerReportValues.push(item?.count || 0)
      })
      commit('setCustomerXLabels', customerXLabels)
      commit('setCustomerValues', customerReportValues)
    },
    async handleGetCustomerReportData({ commit }, payload) {
      const res = await getCustomerReportList(payload)
      commit('setCustomerReportCount', res?.data?.data?.total || 0)
      commit('setCustomerReportList', res?.data?.data?.records || [])
      return res
    },
    async handleApplicationReportList({ commit }, payload) {
      const res = await getApplicationStatistics(payload)
      const appXLabels = []
      const appReportValues = []
      const list = res?.data?.data || []
      list.forEach((item) => {
        appXLabels.push(item?.tenantName)
        appReportValues.push(item?.count || 0)
      })
      commit('setApplicationLabels', appXLabels)
      commit('setApplicationValues', appReportValues)
    },
    async handleGetApplicationReportData({ commit }, payload) {
      const res = await getApplicationReportList(payload)
      commit('setApplicationReportCount', res?.data?.data?.total || 0)
      commit('setApplicationReportList', res?.data?.data?.records || [])
      return res
    },
    async handleMerchantReportList({ commit }, payload) {
      const res = await getMerchantStatistics(payload)
      const merchantXLabels = []
      const merchantReportValues = []
      const list = res?.data?.data || []
      list.forEach((item) => {
        merchantXLabels.push(item?.tenantName)
        merchantReportValues.push(item?.count || 0)
      })
      commit('setMerchantXLabels', merchantXLabels)
      commit('setMerchantValues', merchantReportValues)
    },
    async handleGetMerchantReportData({ commit }, payload) {
      const res = await getMerchantReportList(payload)
      commit('setMerchantReportCount', res?.data?.data?.total || 0)
      commit('setMerchantReportList', res?.data?.data?.records || [])
      return res
    },
    async handleTerminalReportList({ commit }, payload) {
      const res = await getTerminalStatistics(payload)
      const terminalXLabels = []
      const terminalReportValues = []
      const list = res?.data?.data || []
      list.forEach((item) => {
        terminalXLabels.push(item?.tenantName)
        terminalReportValues.push(item?.count || 0)
      })

      commit('setTerminalXLabels', terminalXLabels)
      commit('setTerminalValues', terminalReportValues)
    },
    async handleGetTerminalReportData({ commit }, payload) {
      const res = await getTerminalReportList(payload)
      commit('setTerminalReportCount', res?.data?.data?.total || 0)
      commit('setTerminalReportList', res?.data?.data?.records || [])
      return res
    },
    async downLoadCustomerReport(context, payload) {
      const res = await downLoadCustomerReport(payload)
      return res
    },
    async downLoadMerchantReport(context, payload) {
      const res = await downLoadMerchantReport(payload)
      return res
    },
    async downLoadTerminalReport(context, payload) {
      const res = await downLoadTerminalReport(payload)
      return res
    },
    async downLoadTerminalAppReport(context, payload) {
      const res = await downLoadTerminalAppReport(payload)
      return res
    }
  }
}
