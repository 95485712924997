import { cloneDeep } from 'lodash'
import moment from 'moment-timezone'
/**
 * @description 生成唯一 uuid
 * @return string
 */
export function generateUUID() {
  if (typeof crypto === 'object') {
    if (typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID()
    }
    if (
      typeof crypto.getRandomValues === 'function' &&
      typeof Uint8Array === 'function'
    ) {
      const callback = (c) => {
        const num = Number(c)
        return (
          num ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))
        ).toString(16)
      }
      return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, callback)
    }
  }
  let timestamp = new Date().getTime()
  let performanceNow =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let random = Math.random() * 16
    if (timestamp > 0) {
      random = (timestamp + random) % 16 | 0
      timestamp = Math.floor(timestamp / 16)
    } else {
      random = (performanceNow + random) % 16 | 0
      performanceNow = Math.floor(performanceNow / 16)
    }
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16)
  })
}

// File类型转Base64
export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    let fileResult = ''
    reader.readAsDataURL(file)
    reader.onload = function () {
      fileResult = reader.result
    }
    reader.onerror = function (err) {
      reject(err)
    }
    reader.onloadend = function () {
      resolve(fileResult)
    }
  })
}

export const getCurrentTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

const dataToFile = (params) => {
  const type = params.type
  const fileName = params.fileName
  const data = params.data
  // 兼容 IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(new Blob([data]), fileName)
  } else {
    // 非IE 浏览器
    const url = window.URL.createObjectURL(new Blob([data], { type }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}`)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url) // 释放内存
    document.body.removeChild(link)
  }
}

/*
 * reporttype 报表类型
 * res 报表数据
 * reportName 报表名称
 */
export const exportFile = (reporttype, res, reportName) => {
  if (reporttype === 'doc') {
    const dataSource = {
      data: res.data,
      fileName: reportName + new Date().getTime() + '.doc',
      type: 'application/octet-stream'
    }
    dataToFile(dataSource)
  } else if (reporttype === 'xlsx') {
    const dataSource = {
      data: res.data,
      fileName: reportName + new Date().getTime() + '.xlsx',
      type: 'application/vnd.ms-excel'
    }
    dataToFile(dataSource)
  } else if (reporttype === 'pdf') {
    const dataSource = {
      data: res.data,
      fileName: reportName + new Date().getTime() + '.pdf',
      type: 'application/pdf'
    }
    dataToFile(dataSource)
  } else {
    console.warn('reporttype is not belong the pdf word excel, pls check!')
  }
}

// 计数函数

function countChar(str, target) {
  let b = str.indexOf(target)
  let num = 0
  while (b !== -1) {
    num++
    b = str.indexOf(target, b + 1)
  }
  return num
}
export const handleIsRight = (before, after, text) => {
  // TODO需要严格处理下这段 Compare
  // 定位新增字符串位置
  const insertIndex = after.indexOf(text)
  const beforeInsert = before.slice(0, insertIndex)
  // const afterInsert = after.slice(insertIndex + text.length)
  // 判断前后括号数量
  const beforeLeftCount = countChar(beforeInsert, '$')
  const beforeLeftCount2 = countChar(beforeInsert, '${')
  const beforeRightCount = countChar(beforeInsert, '}')

  return (
    beforeLeftCount === beforeRightCount &&
    beforeLeftCount2 === beforeRightCount
  )
}

// 使用示例 传入需要的数据格式即可转换
// formatDateTime(date, 'yyyy-MM-dd HH:mm:ss') -> 2023-02-18 21:49:05
// formatDateTime(date, 'yyyy年MM月dd日 HH:mm:ss') -> 2023年02月18日 21:49:05
// formatDateTime(date, 'yyyy-MM-dd HH:mm:ss S') -> 2023-02-18 21:49:05 950
// formatDateTime(date, 'yyyy-MM-dd hh:mm:ss A') -> 2023-02-18 21:49:05 下午

export const formatDateTime = (date, format) => {
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时
    'H+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
    a: date.getHours() < 12 ? '上午' : '下午', // 上午/下午
    A: date.getHours() < 12 ? 'AM' : 'PM' // AM/PM
  }
  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return format
}

// export const downLoadFile = (url) => {
//   const link = document.createElement('a')
//   link.style.display = 'none'
//   link.href = url
//   document.body.appendChild(link)
//   link.click()
// }

export const handleUri = (uri) => {
  const result = uri.includes('#') ? uri.split('#')[1] : uri
  let count = 0
  result.split('').forEach((item) => {
    if (item === '/') {
      count++
    } else {
      count = count * 1
    }
  })
  const firstRoute = uri.includes('#') ? uri.split('#')[0].split('/')[1] : ''
  const finalResult = count === 1 ? `/${firstRoute}${result}` : result
  return finalResult
}

// 用来转化对象为options数组，传入{ key: value }数据格式的对象即可
// export const constToOptions = (obj) => {
//   return Object.keys(obj).map((item) => {
//     return {
//       label: item,
//       value: obj[item]
//     }
//   })
// }

// 将number格式化以逗号分割，且只保留2位小数 12345.123 => 12,345.12
export const formateNumber = (value) => {
  if (value === null || value === undefined) {
    return ''
  } else if (value === 0) {
    return 0
  } else {
    let prev = ''
    value = value.toString()
    if (value.includes('-')) {
      prev = '-'
    }
    value = value.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
    value = value.replace(/^\./g, '') // 验证第一个字符是数字而不是.
    value = value.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
    value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.') // 保证.只出现一次，而不能出现两次以上
    value = (+value).toFixed(2) // 只能输入两个小数
    const left = splitNumber(value.split('.')[0])
    const right = value.split('.')[1]
    value = prev + left + '.' + right
    return value
  }
}

// 数值三位以，隔开
export const splitNumber = (n) => {
  var b = parseInt(n).toString()
  var len = b.length
  if (len <= 3) {
    return b
  }
  var r = len % 3
  return r > 0
    ? b.slice(0, r) + ',' + b.slice(r, len).match(/\d{3}/g).join(',')
    : b.slice(r, len).match(/\d{3}/g).join(',')
}

// 格式化Number、Count等数量
export function formatNum(number) {
  if (!number && number !== 0) {
    return '' // 输入为空时，直接返回空字符串
  }

  if (typeof number !== 'string') {
    number = number.toString() // 将输入转换为字符串类型
  }
  number = number.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符

  // 处理负数情况
  if (number.startsWith('-')) {
    number = number.slice(1) // 去掉负号
    return '-' + number.replace(/\B(?=(\d{3})+(?!\d))/g, ',') // 格式化其余部分
  }

  // 处理小数位数
  const parts = number.split('.')
  const integerPart = parts[0] // 整数部分
  // const decimalPart = parts[1] ? '.' + parts[1] : '' // 小数部分
  return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// 比较两个数组的每一项是否相同
// 先将数组以每一项的label进行排序再进行比较
export const isSameItem = (arr1, arr2) => {
  const temp1 = cloneDeep(arr1)
  temp1.sort((a, b) => {
    const nameA = a.label.toUpperCase()
    const nameB = b.label.toUpperCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })
  const temp2 = cloneDeep(arr2)
  temp2.sort((a, b) => {
    const nameA = a.label.toUpperCase()
    const nameB = b.label.toUpperCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })
  return JSON.stringify(temp1) === JSON.stringify(temp2)
}

// 用于将cosnt文件定义的对象转化为options数组
export const constToOptions = (
  textMap,
  valList,
  isNeedPreFix = false,
  labelField = 'label',
  valueField = 'value'
) => {
  let list = Object.keys(valList)
  list = list.map((val) => {
    if (isNeedPreFix) {
      return {
        [labelField]: valList[val] + ' - ' + textMap[val],
        [valueField]: valList[val]
      }
    } else {
      return {
        [labelField]: textMap[val],
        [valueField]: valList[val]
      }
    }
  })
  return list
}

// 获取当前时间的年月日
export const getStandardDate = () => {
  const _date = new Date()
  const year = _date.getFullYear()
  let month = _date.getMonth() + 1
  let day = _date.getDate()
  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  return year + '-' + month + '-' + day
}

// 获取当前时间的年月日往前推迟七天
export const getStandardDateBeforeWeek = () => {
  var _date = new Date()
  _date.setDate(_date.getDate() - 7) // 日期回到七天前
  var year = _date.getFullYear()
  var month = _date.getMonth() + 1
  var day = _date.getDate()
  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  var dateTemp = year + '-' + month + '-' + day
  _date.setDate(_date.getDate() + 7) // 日期重置
  return dateTemp
}

// 判断字符串是否为base64格式
export const isBase64 = (str) => {
  if (str.indexOf('data:') !== -1 && str.indexOf('base64') !== -1) {
    return true
  } else {
    return false
  }
}

export const downloadFile = async (fileUrl, fileName) => {
  // 1. 获取文件内容
  const res = await fetch(fileUrl)
  const fileContent = await res.blob()

  // 2. 构造Blob对象
  const blob = new Blob([fileContent])

  // 3. 生成文件URL
  const url = URL.createObjectURL(blob)

  // 4. 创建a标签并触发点击下载
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  // 5. 释放URL
  URL.revokeObjectURL(url)
}
export const generateColLayout = (span) => {
  return {
    xl: span,
    lg: span,
    md: span,
    sm: span,
    xs: span
  }
}

export const findCurrentSelectedTreeNode = (
  treeData,
  currentSelectedName,
  prop
) => {
  // 遍历当前层级的所有节点
  for (var i = 0; i < treeData.length; i++) {
    var node = treeData[i]
    if (node[prop] === currentSelectedName) {
      if (prop === 'id') {
        return node ? node?.name : ''
      } else if (prop === 'name') {
        return node ? node?.id : ''
      }
    }
    if (
      node.children &&
      Array.isArray(node.children) &&
      node.children.length > 0
    ) {
      var foundNode = findCurrentSelectedTreeNode(
        node.children,
        currentSelectedName,
        prop
      )
      if (foundNode) {
        return foundNode
      }
    }
  }

  // 如果遍历完所有节点仍未找到目标节点，则返回 ''
  return ''
}

// 将UTC时间转换为指定时区的格式化时间字符串
export const getCurrentSystemTimeFormat = (
  utcTime,
  timeZone = moment.tz.guess(true),
  formatString = 'YYYY-MM-DD HH:mm:ss'
) => {
  if (!utcTime) return ''
  // 使用传入的UTC时间字符串来创建一个moment对象
  const momentUTC = moment.utc(utcTime)

  // 将UTC时间转换为指定的时区
  const momentLocal = momentUTC.tz(timeZone)

  // 使用指定的格式字符串来格式化时间
  const formattedTime = momentLocal.format(formatString)

  // 返回格式化后的时间字符串
  return formattedTime
}

export function convertDateStringToTimezoneOnlyDate(dateString, field) {
  if (!dateString) return ''
  // 验证 dateString 是否为有效的日期格式
  const momentDate = moment(dateString, 'YYYY-MM-DD', true)
  if (!momentDate.isValid()) {
    throw new Error('Invalid date string provided')
  }

  // 根据 field 添加时间
  let formattedDateString
  if (field === 'startDateTime') {
    // 设置开始时间为当天00:00:00 UTC
    formattedDateString = momentDate
      .startOf('day')
      .utc() // 转换为UTC时间
      .format('YYYY-MM-DD HH:mm:ss')
  } else if (field === 'endDateTime') {
    // 设置结束时间为当天23:59:59 UTC
    formattedDateString = momentDate
      .clone()
      .endOf('day')
      .utc() // 转换为UTC时间
      .format('YYYY-MM-DD HH:mm:ss')
  } else {
    throw new Error(
      'Invalid field provided. Expected "startDateTime" or "endDateTime"'
    )
  }

  // 返回格式化后的UTC日期时间字符串
  return formattedDateString
}
