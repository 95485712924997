import request from '../utils/request'

export const getGeoFenceListByPage = (data) => {
  return request({
    url: `/rest/geoFence/getPage`,
    data,
    method: 'post'
  })
}

export const handleNewGeoFence = (data) => {
  return request({
    url: '/rest/geoFence/add',
    data,
    method: 'post'
  })
}

export const handleUpdateGeoFence = (data) => {
  return request({
    url: '/rest/geoFence/edit',
    data,
    method: 'put'
  })
}

export const handleDeleteGeoFence = (id) => {
  return request({
    url: `/rest/geoFence/del/${id}`,
    method: 'delete'
  })
}

export const getGeoFenceById = (data) => {
  return request({
    url: `/rest/geoFence/get/${data.id}`,
    method: 'get'
  })
}

export const getGeoFenceGroupListByPage = (data) => {
  return request({
    url: `/rest/geoFence/group/getPage`,
    data,
    method: 'post'
  })
}

export const handleNewGeoFenceGroup = (data) => {
  return request({
    url: '/rest/geoFence/group/add',
    data,
    method: 'post'
  })
}

export const handleUpdateGeoFenceGroup = (data) => {
  return request({
    url: '/rest/geoFence/group/edit',
    data,
    method: 'put'
  })
}

export const handleDeleteGeoFenceGroup = (id) => {
  return request({
    url: `/rest/geoFence/group/del/${id}`,
    method: 'delete'
  })
}

export const getGeoFenceGroupById = (data) => {
  return request({
    url: `/rest/geoFence/group/get/${data.id}`,
    method: 'get'
  })
}

export const getGeoFenceSettingListByPage = (data) => {
  return request({
    url: `/rest/geoFence/groupSetting/getPage`,
    data,
    method: 'post'
  })
}

export const handleNewGeoFenceGroupSetting = (data) => {
  return request({
    url: '/rest/geoFence/groupSetting/add',
    data,
    method: 'post'
  })
}

export const handleUpdateGeoFenceGroupSetting = (data) => {
  return request({
    url: '/rest/geoFence/groupSetting/edit',
    data,
    method: 'put'
  })
}

export const handleDeleteGeoFenceGroupSetting = (id) => {
  return request({
    url: `/rest/geoFence/groupSetting/del/${id}`,
    method: 'delete'
  })
}

export const getGeoFenceGroupSettingById = (data) => {
  return request({
    url: `/rest/geoFence/groupSetting/get/${data.id}`,
    method: 'get'
  })
}
