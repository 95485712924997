/*****
 * @desc use to handle the context of user
 * @author Blank
 * @date 2023-3-24
 */
// import { useRouter } from 'vue-router'
import {
  getParamLists,
  getParamById,
  getParamTypeList,
  getParamStatusList,
  getParamValueTypeList,
  handleAddParamSubmit,
  handleUpdateParamSubmit,
  deleteParamById,
  deleteSelectParam,
  getAIDLists,
  getAIDById,
  deleteAIDById,
  handleAddAID,
  handleUpdateAID,
  getCapkLists,
  getCapkById,
  deleteCapkById,
  handleAddCapk,
  handleUpdateCapk,
  getTemplateList,
  getTemplateById,
  deleteTemplateById,
  handleAddTemplate,
  handleUpdateTemplate,
  getTemplateGroupList,
  getPageForGroupList,
  getTemplateGroupById,
  deleteTemplateGroupById,
  handleAddTemplateGroup,
  handleUpdateTemplateGroup,
  getTemplateGroupSettingList,
  getTemplateGroupSettingById,
  deleteTemplateGroupSettingById,
  handlePublishTemplateGroup,
  handleAddTemplateGroupSetting,
  handleUpdateTemplateGroupSetting,
  getCardLists,
  handleAddCardSubmit,
  getCardListByID,
  deleteCard,
  handleEditCardSubmit,
  getCardBinLists,
  handleAddCardBinSubmit,
  getCardBinListByID,
  handleEditCardBinSubmit,
  deleteCardBin,
  handleTerminalCardBinDisableAction,
  handleTerminalCardBinEnableAction,
  handleTerminalCardDisableAction,
  handleTerminalCardEnableAction,
  handleTerminalParamAidDisableAction,
  handleTerminalParamAidEnableAction,
  handleTerminalParamCapkDisableAction,
  handleTerminalParamCapkEnableAction,
  handleTerminalParamDisableAction,
  handleTerminalParamEnableAction,
  handleTerminalParamTemplateDisableAction,
  handleTerminalParamTemplateEnableAction,
  getAllParameters
} from '../../service/param'

export default {
  namespaced: true,
  state: () => ({
    parameterCount: 0,
    parameterList: [],
    templateList: [],
    templateCount: 0,
    templateGroupList: [],
    templateGroupCount: 0,
    templateSettingList: [],
    templateSettingCount: 0,
    cardList: [],
    cardCount: 0,
    cardBinList: [],
    cardBinCount: 0,
    aidList: [],
    aidCount: 0,
    capkList: [],
    capkCount: 0
  }),
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    setParamsCount(state, parameterCount) {
      state.parameterCount = parameterCount
    },
    setParamsList(state, parameterList) {
      state.parameterList = parameterList
    },
    setTemplateList(state, templateList) {
      state.templateList = templateList
    },
    setTemplateCount(state, templateCount) {
      state.templateCount = templateCount
    },
    setAidList(state, aidList) {
      state.aidList = aidList
    },
    setAidCount(state, aidCount) {
      state.aidCount = aidCount
    },
    setCapkList(state, capkList) {
      state.capkList = capkList
    },
    setCapkCount(state, capkCount) {
      state.capkCount = capkCount
    },
    setTemplateGroupList(state, templateGroupList) {
      state.templateGroupList = templateGroupList
    },
    setTemplateGroupCount(state, templateGroupCount) {
      state.templateGroupCount = templateGroupCount
    },
    setTemplateGroupSettingList(state, templateSettingList) {
      state.templateSettingList = templateSettingList
    },
    setTemplateGroupSettingCount(state, templateSettingCount) {
      state.templateSettingCount = templateSettingCount
    },
    setCardList(state, cardList) {
      state.cardList = cardList
    },
    setCardCount(state, cardCount) {
      state.cardCount = cardCount
    },
    setCardBinList(state, cardBinList) {
      state.cardBinList = cardBinList
    },
    setCardBinCount(state, cardBinCount) {
      state.cardBinCount = cardBinCount
    }
  },
  actions: {
    async getParamListData(context, payload) {
      const res = await getParamLists(payload)
      this.commit('param/setParamsList', res?.data?.data?.records || [])
      this.commit('param/setParamsCount', res?.data?.data?.total || 0)
      return res
    },
    async getParamTypeList(context, payload = {}) {
      const resType = await getParamTypeList(payload)
      const resultType = Object.keys(resType.data).map((i) => {
        return {
          id: i,
          name: resType.data[i]
        }
      })
      return resultType
    },
    async getParamStatusList(context, payload = {}) {
      const resStatus = await getParamStatusList(payload)
      const resultStatus = Object.keys(resStatus.data).map((i) => {
        return {
          id: i,
          name: resStatus.data[i]
        }
      })
      return resultStatus
    },
    async getParamValueTypeList(context, payload = {}) {
      const resValueType = await getParamValueTypeList(payload)
      const resultValueType = Object.keys(resValueType.data).map((i) => {
        return {
          id: i,
          name: resValueType.data[i]
        }
      })
      return resultValueType
    },
    async handleParamAction(context, payload) {
      const res = await handleAddParamSubmit(payload)
      return res
    },
    async handleUpdateParamSubmit(context, payload) {
      const res = await handleUpdateParamSubmit(payload)
      return res
    },
    async handleGetParam(context, payload) {
      const res = await getParamById(payload)
      return res?.data?.data || {}
    },
    async handleDeleteParam({ dispatch }, payload) {
      const res = await deleteParamById(payload)
      return res
    },
    async deleteMultiParam({ dispatch }, payload) {
      const res = await deleteSelectParam(payload)
      return res
    },
    async getAIDLists({ dispatch }, payload) {
      const res = await getAIDLists(payload)
      this.commit('param/setAidList', res?.data?.data?.records || [])
      this.commit('param/setAidCount', res?.data?.data?.total || 0)
      return res
    },
    async getAIDById({ dispatch }, payload) {
      const res = await getAIDById(payload)
      return res?.data?.data || {}
    },
    async deleteAIDById({ dispatch }, payload) {
      const res = await deleteAIDById(payload)
      return res
    },
    async handleAddAID({ dispatch }, payload) {
      const res = await handleAddAID(payload)
      return res
    },
    async handleUpdateAID({ dispatch }, payload) {
      const res = await handleUpdateAID(payload)
      return res
    },
    async getCapkLists({ dispatch }, payload) {
      const res = await getCapkLists(payload)
      this.commit('param/setCapkList', res?.data?.data?.records || [])
      this.commit('param/setCapkCount', res?.data?.data?.total || 0)
      return res
    },
    async getCapkById({ dispatch }, payload) {
      const res = await getCapkById(payload)
      return res?.data?.data || {}
    },
    async deleteCapkById({ dispatch }, payload) {
      const res = await deleteCapkById(payload)
      return res
    },
    async handleAddCapk({ dispatch }, payload) {
      const res = await handleAddCapk(payload)
      return res
    },
    async handleUpdateCapk({ dispatch }, payload) {
      const res = await handleUpdateCapk(payload)
      return res
    },
    async getTemplateGroupList({ dispatch }, payload) {
      const res = await getTemplateGroupList(payload)
      this.commit('param/setTemplateGroupList', res?.data?.data?.records || [])
      this.commit('param/setTemplateGroupCount', res?.data?.data?.total || 0)
      return res
    },
    async getPageForGroupList({ dispatch }, payload) {
      const res = await getPageForGroupList(payload)
      return res?.data?.data || []
    },
    async getTemplateGroupById({ dispatch }, payload) {
      const res = await getTemplateGroupById(payload)
      return res?.data?.data || {}
    },
    async deleteTemplateGroupById({ dispatch }, payload) {
      const res = await deleteTemplateGroupById(payload)
      return res
    },
    async handleAddTemplateGroup({ dispatch }, payload) {
      const res = await handleAddTemplateGroup(payload)
      return res
    },
    async handleUpdateTemplateGroup({ dispatch }, payload) {
      const res = await handleUpdateTemplateGroup(payload)
      return res
    },
    async getTemplateGroupSettingList({ dispatch }, payload) {
      const res = await getTemplateGroupSettingList(payload)
      this.commit(
        'param/setTemplateGroupSettingList',
        res?.data?.data?.records || []
      )
      this.commit(
        'param/setTemplateGroupSettingCount',
        res?.data?.data?.total || 0
      )
      return res
    },
    async getTemplateGroupSettingById({ dispatch }, payload) {
      const res = await getTemplateGroupSettingById(payload)
      return res?.data?.data || {}
    },
    async deleteTemplateGroupSettingById({ dispatch }, payload) {
      const res = await deleteTemplateGroupSettingById(payload)
      return res
    },
    async handlePublishTemplateGroup({ dispatch }, payload) {
      const res = await handlePublishTemplateGroup(payload)
      return res
    },
    async handleAddTemplateGroupSetting({ dispatch }, payload) {
      const res = await handleAddTemplateGroupSetting(payload)
      return res
    },
    async handleUpdateTemplateGroupSetting({ dispatch }, payload) {
      const res = await handleUpdateTemplateGroupSetting(payload)
      return res
    },
    async getTemplateList({ dispatch }, payload) {
      const res = await getTemplateList(payload)
      this.commit('param/setTemplateList', res?.data?.data?.records || [])
      this.commit('param/setTemplateCount', res?.data?.data?.total || 0)
      return res
    },
    async getTemplateById({ dispatch }, payload) {
      const res = await getTemplateById(payload)
      return res?.data?.data || {}
    },
    async deleteTemplateById({ dispatch }, payload) {
      const res = await deleteTemplateById(payload)
      return res
    },
    async handleAddTemplate({ dispatch }, payload) {
      const res = await handleAddTemplate(payload)
      return res
    },
    async handleUpdateTemplate({ dispatch }, payload) {
      const res = await handleUpdateTemplate(payload)
      return res
    },
    async getCardLists(context, payload) {
      const res = await getCardLists(payload)
      this.commit('param/setCardList', res?.data?.data?.records || [])
      this.commit('param/setCardCount', res?.data?.data?.total || 0)
      return res
    },
    async handleAddCardAction(context, payload) {
      const res = await handleAddCardSubmit(payload)
      return res
    },
    async getCardListByID(context, payload) {
      const res = await getCardListByID(payload)
      return res?.data?.data || {}
    },
    async handleDeleteCard(context, payload) {
      const res = await deleteCard(payload)
      return res
    },
    async handleEditCardAction(context, payload) {
      const res = await handleEditCardSubmit(payload)
      return res
    },
    async getCardBinLists(context, payload) {
      const res = await getCardBinLists(payload)
      this.commit('param/setCardBinList', res?.data?.data?.records || [])
      this.commit('param/setCardBinCount', res?.data?.data?.total || 0)
      return res
    },
    async handleAddCardBinAction(context, payload) {
      const res = await handleAddCardBinSubmit(payload)
      return res
    },
    async getCardBinListByID(context, payload) {
      const res = await getCardBinListByID(payload)
      return res?.data?.data || {}
    },
    async handleEditCardBinSubmit(context, payload) {
      const res = await handleEditCardBinSubmit(payload)
      return res
    },
    async handleDeleteCardBin(context, payload) {
      const res = await deleteCardBin(payload)
      return res
    },
    async handleTerminalCardBinDisableAction({ dispatch }, payload) {
      const res = await handleTerminalCardBinDisableAction(payload)
      return res
    },
    async handleTerminalCardBinEnableAction({ dispatch }, payload) {
      const res = await handleTerminalCardBinEnableAction(payload)
      return res
    },
    async handleTerminalCardDisableAction({ dispatch }, payload) {
      const res = await handleTerminalCardDisableAction(payload)
      return res
    },
    async handleTerminalCardEnableAction({ dispatch }, payload) {
      const res = await handleTerminalCardEnableAction(payload)
      return res
    },
    async handleTerminalParamAidDisableAction({ dispatch }, payload) {
      const res = await handleTerminalParamAidDisableAction(payload)
      return res
    },
    async handleTerminalParamAidEnableAction({ dispatch }, payload) {
      const res = await handleTerminalParamAidEnableAction(payload)
      return res
    },
    async handleTerminalParamCapkDisableAction({ dispatch }, payload) {
      const res = await handleTerminalParamCapkDisableAction(payload)
      return res
    },
    async handleTerminalParamCapkEnableAction({ dispatch }, payload) {
      const res = await handleTerminalParamCapkEnableAction(payload)
      return res
    },
    async handleTerminalParamDisableAction({ dispatch }, payload) {
      const res = await handleTerminalParamDisableAction(payload)
      return res
    },
    async handleTerminalParamEnableAction({ dispatch }, payload) {
      const res = await handleTerminalParamEnableAction(payload)
      return res
    },
    async handleTerminalParamTemplateDisableAction({ dispatch }, payload) {
      const res = await handleTerminalParamTemplateDisableAction(payload)
      return res
    },
    async handleTerminalParamTemplateEnableAction({ dispatch }, payload) {
      const res = await handleTerminalParamTemplateEnableAction(payload)
      return res
    },
    async getAllParameters(context, payload) {
      const res = await getAllParameters(payload)
      return res?.data?.data || []
    }
  }
}
