<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item v-for="(item, index) in breadList" :key="item.path">
      <router-link
        style="font-size: 12px"
        :to="item.path ? item.path : '/home'"
        v-if="index < breadList.length - 1"
        >{{ item.title }}</router-link
      >
      <span v-else>{{ item.title }}</span>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script setup>
import { generateTitle, generateParentTitle } from '@/utils/i18n'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const breadList = computed(() => {
  const list = route.matched

  // list的数据格式一般为[{path: '/', name: home },{path: '/category/edit/:id', name: 'categoryEdit'}]两层结构
  const array = []
  for (let i = 0; i < list.length; i++) {
    // 先push home项
    if (i === 0) {
      array.push({
        title: generateTitle(list[i].meta.title),
        path: list[i].path
      })
    }
    // 第一层的home没有parentTitle，当走第二层时，push路由表中该路由定义的parentTitle数组，parentTitle为该路由的一个或多个父级路由名称和路径组成
    if (list[i].meta.parentTitle) {
      list[i].meta.parentTitle.forEach((item) => {
        array.push({ title: generateParentTitle(item.title), path: item.path })
      })
    }
    // 最后push当前的路由的title和path
    if (i > 0) {
      if (list[i].meta.title === 'home') return array
      array.push({
        title: generateTitle(list[i].meta.title),
        path: list[i].path
      })
    }
  }
  // 面包屑则由 home -> parentTitle(数组，可以一个或多个父级) -> 当前路由title组成
  return array
})
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  :deep(.no-redirect) {
    color: #97a8be;
    cursor: text;
  }
}
</style>
