import request from '../utils/request'

export const getApplicationLists = (data) => {
  return request({
    url: '/rest/terminalApp/getPage',
    data: data,
    method: 'post'
  })
}

export const getApplicationTypeList = (data) => {
  return request({
    url: '/rest/category/get',
    data: data,
    method: 'post'
  })
}

export const handleApplicationSubmit = (data) => {
  return request({
    url: '/rest/terminalApp/add',
    data: data,
    method: 'post'
    // otherOptions: {
    //   headers: { 'Content-Type': 'multipart/form-data' }
    // }
  })
}

export const handleImportApplicationFile = (data) => {
  return request({
    url: '/rest/terminalAppFile/add',
    data: data,
    method: 'post'
  })
}

export const handleApplicationEdit = (data) => {
  return request({
    url: '/rest/terminalApp/edit',
    data: data,
    method: 'put'
  })
}

export const deleteApplicationById = (id) => {
  return request({
    url: `/rest/terminalApp/del/${id}`,
    method: 'delete'
  })
}
export const handlePublishApplicationAction = (id) => {
  return request({
    url: `/rest/terminalApp/push/${id}`,
    method: 'post'
  })
}
export const handleApplicationDisableAction = (id) => {
  return request({
    url: `/rest/terminalApp/disable/${id}`,
    method: 'put'
  })
}
export const handleApplicationEnableAction = (id) => {
  return request({
    url: `/rest/terminalApp/enable/${id}`,
    method: 'put'
  })
}

export const getApplicationById = (data) => {
  return request({
    url: `/rest/terminalApp/get/${data.id}`,
    // data: id,
    method: 'get'
  })
}

export const getApplicationFileLists = (data) => {
  return request({
    url: '/rest/terminalAppFile/getPage',
    data: data,
    method: 'post'
  })
}

export const deleteApplicationFile = (id) => {
  return request({
    url: `/rest/terminalAppFile/del/${id}`,
    method: 'delete'
  })
}

export const handleDisableApplicationAction = (id) => {
  return request({
    url: `/rest/terminalAppFile/disable/${id}`,
    method: 'put'
  })
}
export const handleEnableApplicationAction = (id) => {
  return request({
    url: `/rest/terminalAppFile/enable/${id}`,
    method: 'put'
  })
}

export const handlePushApplicationAction = (id) => {
  return request({
    url: `/rest/terminalAppFile/push/${id}`,
    method: 'put'
  })
}

export const getStoreListData = (data) => {
  return request({
    url: '/rest/apphome/getpage',
    data: data,
    method: 'post'
  })
}

export const getStore = () => {
  return request({
    url: '/rest/store/get',
    method: 'get'
  })
}

export const updateStore = (data) => {
  return request({
    url: '/rest/store/update',
    data,
    method: 'post',
    otherOptions: {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  })
}

export const downLoadApp = (id) => {
  return request({
    url: `/rest/apps/downloadApp/${id}`,
    method: 'get'
  })
}

export const countDownload = (id) => {
  return request({
    url: `/rest/embeddedApp/count/${id}`,
    method: 'get'
  })
}

export const getOtaLists = (data) => {
  return request({
    url: `/rest/terminalOta/getPage`,
    data,
    method: 'post'
  })
}

export const getOtaFileLists = (data) => {
  return request({
    url: `/rest/terminalOtaFile/getPage`,
    data,
    method: 'post'
  })
}
export const deleteOtaFileById = (id) => {
  return request({
    url: `/rest/terminalOtaFile/del/${id}`,
    method: 'delete'
  })
}

export const deleteOtaById = (id) => {
  return request({
    url: `/rest/terminalOta/del/${id}`,
    method: 'delete'
  })
}
export const getOtaStatusList = (data) => {
  return request({
    url: '/rest/apps/getStatus',
    data: data,
    method: 'get'
  })
}

export const handleOtaSubmit = (data) => {
  return request({
    url: '/rest/terminalOta/add',
    data: data,
    method: 'post'
  })
}

export const handleImportOtaFile = (data) => {
  return request({
    url: '/rest/terminalOtaFile/add',
    data: data,
    method: 'post'
    // otherOptions: {
    //   headers: { 'Content-Type': 'multipart/form-data' }
    // }
  })
}

export const getOtaById = (data) => {
  return request({
    url: `/rest/terminalOta/get/${data.id}`,
    method: 'get'
  })
}
export const handleUpdateOta = (data) => {
  return request({
    url: '/rest/terminalOta/edit',
    data: data,
    method: 'put'
  })
}
export const handlePublishOtaAction = (id) => {
  return request({
    url: `/rest/terminalOtaFile/push/${id}`,
    method: 'put'
  })
}

export const handleReleaseOtaAction = (id) => {
  return request({
    url: `/rest/terminalOtaFile/release/${id}`,
    method: 'put'
  })
}

export const handleDisableOtaAction = (id) => {
  return request({
    url: `/rest/terminalOta/disable/${id}`,
    method: 'put'
  })
}
export const handleEnableOtaAction = (id) => {
  return request({
    url: `/rest/terminalOta/enable/${id}`,
    method: 'put'
  })
}

export const handleDisableOtaFileAction = (id) => {
  return request({
    url: `/rest/terminalOtaFile/disable/${id}`,
    method: 'put'
  })
}
export const handleEnableOtaFileAction = (id) => {
  return request({
    url: `/rest/terminalOtaFile/enable/${id}`,
    method: 'put'
  })
}

export const getOtaCount = (id) => {
  return request({
    url: `/rest/terminalOta/count/${id}`,
    method: 'get'
  })
}

export const getWelcomePage = (data) => {
  return request({
    url: '/rest/welcomePage/getPage',
    data: data,
    method: 'POST'
  })
}

export const getWelcomeDetailByID = (data) => {
  return request({
    url: `/rest/welcomePage/get/${data.id}`,
    method: 'GET'
  })
}

export const deleteWelcomeByID = () => {
  return request({
    url: '/rest/welcomePage/del',
    method: 'DELETE'
  })
}

export const handleUpdateWelcomePage = (data) => {
  return request({
    url: '/rest/welcomePage/edit',
    data: data,
    method: 'PUT'
  })
}

export const handleAddWelcomePage = (data) => {
  return request({
    url: '/rest/welcomePage/add',
    data: data,
    method: 'POST'
  })
}

export const handleDisableWelcomePageAction = (id) => {
  return request({
    url: `/rest/welcomePage/disable/${id}`,
    method: 'put'
  })
}
export const handleEnableWelcomePageAction = (id) => {
  return request({
    url: `/rest/welcomePage/enable/${id}`,
    method: 'put'
  })
}
