import throttle from './throttle' // 引入需要的指令
import copy from './copy'

const directivesList = {
  throttle, // 挂载
  copy
}

const directives = {
  install: function (app) {
    Object.keys(directivesList).forEach((key) => {
      app.directive(key, directivesList[key]) // 注册
    })
  }
}

export default directives // 抛出
