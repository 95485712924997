// 国际化语言
export const LANG = 'en'

export const ISLOGIN = 'isLogin'

export const USERNAME = 'userName'
export const PASSWORD = 'password'

// Tags
export const TAGS_VIEW = 'tagsView'

// 设置允许缓存的tags数量
export const MAX_TAGS_VIEW_SIZE = '20'

// searchDataList
export const SERACH_DATA_LIST = 'searchDataList'

// btnPermissionList
export const BTN_PERMISSION_LIST = 'btnPermissionList'

// menuPermissionList
export const MENU_PERMISSION_LIST = 'menuPermissionList'

// resourceList
export const RESOURCE_LIST = 'resourceList'

// 主题色保存的 key
export const MAIN_COLOR = 'mainColor'
// 默认色值
export const DEFAULT_COLOR = '#304156'

// 资源菜单按钮树
export const RESOURCEMENUBTNLIST = 'resuorceMenuBtnList'

export const HASPOPUP = 'hasPopUp'

export const COLSETTINGTABLEARR = 'colSettingTableArr'

export const COMMONPARAMARR = 'commonParamsArr'
