import {
  getTerminalLists,
  handleEnableTerminal,
  handleDisableTerminal,
  deleteTerminalById,
  deleteMultiTerminal,
  getTerminalStatusList,
  getTerminalById,
  handleTerminalSubmit,
  handleUpdateTerminal,
  handleUpdateModel,
  getModelListByPage,
  getModelList,
  deleteModelById,
  getTerminalStatus,
  getCurrentTerminalRemoteParams,
  startRemoteClient,
  getCurrentTerminalSession,
  acceptTerminalApplication,
  endRemoteClient,
  connectRemoteClient,
  endRemoteClientConnection,
  getBatchLists,
  getBrandLists,
  getRemoteLists,
  handleBatchAdd,
  getCurrentBatch,
  updateCurrentBatch,
  deleteBatchById,
  handleBrandAdd,
  deleteBrandById,
  updateCurrentBrand,
  getCurrentBrand,
  handleModelAdd,
  updateCurrentModel,
  getCurrentModel,
  pushTemplates
} from '../../service/terminal'

export default {
  namespaced: true,
  state: () => ({
    terminalsCount: 0,
    terminalsList: [],
    modelsCount: 0,
    modelsList: [],
    switchLogsCount: 0,
    switchLogsList: [],
    traceLogsList: [],
    terminalsStatusList: [],
    terminalsStatusCount: 0,
    traceLogsCount: 0,
    currentRemoteTerminalId: '',
    isContinueRemote: false,
    batchList: [],
    batchCount: 0,
    brandList: [],
    brandCount: 0,
    currentTerminalSession: {},
    currentAddress: '',
    currentSession: '',

    remoteList: [],
    remoteCount: 0,

    sessionMap: {}
  }),
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    updateApplySession(state, params) {
      state.sessionMap[params.amount] = params.sessionValue
    },
    addApplySession(state, amount) {
      state.sessionMap[amount] = {}
    },
    updateCurrentRemoteTerminalId(state, id) {
      state.currentRemoteTerminalId = id
    },
    updateCurrentRemoteSession(state, currentRemoteSession) {
      state.currentTerminalSession = currentRemoteSession
    },
    updateCurrentAddress(state, address) {
      state.currentAddress = address
    },
    updateCurrentSession(state, session) {
      state.currentSession = session
    },
    updateReconnectRemoteStatus(state) {
      state.isContinueRemote = !state.isContinueRemote
    },
    setTerminalsCount(state, terminalsCount) {
      state.terminalsCount = terminalsCount
    },
    setTerminalsList(state, terminalsList) {
      state.terminalsList = terminalsList
    },
    setModelList(state, modelsList) {
      state.modelsList = modelsList
    },
    setModelCount(state, modelsCount) {
      state.modelsCount = modelsCount
    },
    setSwitchLogsList(state, switchLogsList) {
      state.switchLogsList = switchLogsList
    },
    setSwitchLogsCount(state, switchLogsCount) {
      state.switchLogsCount = switchLogsCount
    },
    setTraceLogsList(state, traceLogsList) {
      state.traceLogsList = traceLogsList
    },
    setTraceLogsCount(state, traceLogsCount) {
      state.traceLogsCount = traceLogsCount
    },
    setTerminalsStatusCount(state, terminalsStatusCount) {
      state.terminalsStatusCount = terminalsStatusCount
    },
    setTerminalsStatusList(state, terminalsStatusList) {
      state.terminalsStatusList = terminalsStatusList
    },
    setBatchCount(state, batchCount) {
      state.batchCount = batchCount
    },
    setBatchList(state, batchList) {
      state.batchList = batchList
    },
    setBrandCount(state, brandCount) {
      state.brandCount = brandCount
    },
    setBrandList(state, brandList) {
      state.brandList = brandList
    },
    setRemoteCount(state, remoteCount) {
      state.remoteCount = remoteCount
    },
    setRemoteList(state, remoteList) {
      state.remoteList = remoteList
    }
  },
  actions: {
    async getTerminalListData(context, payload) {
      const res = await getTerminalLists(payload)
      this.commit('terminal/setTerminalsList', res?.data?.data?.records || [])
      this.commit('terminal/setTerminalsCount', res?.data?.data?.total || 0)
      return res
    },
    async getModelList(context, payload) {
      const res = await getModelList(payload)
      return res?.data || []
    },
    async handleDeleteTerminal({ dispatch }, payload) {
      const res = await deleteTerminalById(payload)
      return res
    },
    async handleEnableTerminal({ dispatch }, payload) {
      const res = await handleEnableTerminal(payload)
      return res
    },
    async handleDisableTerminal({ dispatch }, payload) {
      const res = await handleDisableTerminal(payload)
      return res
    },
    async deleteMultiTerminal({ dispatch }, payload) {
      const res = await deleteMultiTerminal(payload)
      return res
    },
    async getTerminalOptionList(context, payload = {}) {
      const terminalStatus = await getTerminalStatusList(payload)
      const resTerminal = Object.keys(terminalStatus.data).map((i) => {
        return {
          id: i,
          name: terminalStatus.data[i]
        }
      })
      return resTerminal
    },

    async handleGetTerminal(context, payload) {
      const res = await getTerminalById(payload)
      return res?.data?.data || {}
    },
    async getModelListByPage(context, payload = {}) {
      const res = await getModelListByPage(payload)
      this.commit('terminal/setModelList', res?.data?.data?.records || [])
      this.commit('terminal/setModelCount', res?.data?.data?.total || 0)
    },

    async handleTerminalAction(context, payload) {
      const res = await handleTerminalSubmit(payload)
      return res
    },
    async handleUpdateTerminal(context, payload) {
      const res = await handleUpdateTerminal(payload)
      return res
    },

    async getTerminalStatusData(context, payload) {
      const res = await getTerminalStatus(payload)
      this.commit('terminal/setTerminalsStatusList', res.data.data.result)
      this.commit('terminal/setTerminalsStatusCount', res.data.data.totalCount)
      return res
    },
    async handleUpdateModel(context, payload) {
      const res = await handleUpdateModel(payload)
      return res
    },
    async getCurrentTerminalRemoteParams(context, payload) {
      const res = await getCurrentTerminalRemoteParams(payload)
      return res
    },
    async getCurrentTerminalSession(context, payload) {
      const res = await getCurrentTerminalSession(payload)
      return res
    },
    async startRemoteClient(context, payload) {
      const res = await startRemoteClient(payload)
      return res
    },
    async endRemoteClient(context, payload) {
      const res = await endRemoteClient(payload)
      return res
    },
    async connectRemoteClient(context, payload) {
      const res = await connectRemoteClient(payload)
      return res
    },

    async acceptTerminalApplication(context, payload) {
      const res = await acceptTerminalApplication(payload)
      return res
    },
    async endRemoteClientConnection(context, payload) {
      const res = await endRemoteClientConnection(payload)
      return res
    },
    async getBatchLists(context, payload) {
      const res = await getBatchLists(payload)
      this.commit('terminal/setBatchList', res?.data?.data?.records || [])
      this.commit('terminal/setBatchCount', res?.data?.data?.total || 0)
      return res
    },
    async getBrandLists(context, payload) {
      const res = await getBrandLists(payload)
      this.commit('terminal/setBrandList', res?.data?.data?.records || [])
      this.commit('terminal/setBrandCount', res?.data?.data?.total || 0)
      return res
    },
    async getRemoteLists(context, payload) {
      const res = await getRemoteLists(payload)
      this.commit('terminal/setRemoteList', res?.data?.data?.records || [])
      this.commit('terminal/setRemoteCount', res?.data?.data?.length || 0)
      return res
    },
    async handleBatchAdd(context, payload) {
      const res = await handleBatchAdd(payload)
      return res
    },
    async handleBrandAdd(context, payload) {
      const res = await handleBrandAdd(payload)
      return res
    },
    async handleDeleteBrandById(context, payload) {
      const res = await deleteBrandById(payload)
      return res
    },
    async handleBatchUpdate(context, payload) {
      const res = await updateCurrentBatch(payload)
      return res
    },
    async handleBrandUpdate(context, payload) {
      const res = await updateCurrentBrand(payload)
      return res
    },
    async getCurrentBatchById(context, payload) {
      const res = await getCurrentBatch(payload)
      return res?.data?.data
    },
    async getCurrentBrandById(context, payload) {
      const res = await getCurrentBrand(payload)
      return res?.data?.data
    },
    async handleDeleteBatchById(context, payload) {
      const res = await deleteBatchById(payload)
      return res
    },
    async handleModelAdd(context, payload) {
      const res = await handleModelAdd(payload)
      return res
    },
    async updateCurrentModel(context, payload) {
      const res = await updateCurrentModel(payload)
      return res
    },
    async getCurrentModelById(context, payload) {
      const res = await getCurrentModel(payload)
      return res?.data?.data
    },
    async handleDeleteModelById(context, payload) {
      const res = await deleteModelById(payload)
      return res
    },
    async pushTemplates(context, payload) {
      const res = await pushTemplates(payload)
      return res
    }
  }
}
