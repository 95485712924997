import layout from '@/Layout/index.vue'

export default {
  path: '/home',
  component: layout,
  redirect: '/home',
  meta: {
    title: 'home'
  },
  children: [
    {
      path: '/log/operation',
      name: 'OperationLogs',
      component: () => import('@/views/log/operation-log/operation-log.vue'),
      meta: {
        title: 'operation-log',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-log'
          }
        ]
      }
    },
    {
      path: '/log/audit',
      name: 'AuditLogs',
      component: () => import('@/views/log/audit-log/audit-log.vue'),
      meta: {
        title: 'audit-log',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-log'
          }
        ]
      }
    },
    {
      path: '/log/trace',
      name: 'TraceLogs',
      component: () => import('@/views/log/trace-log/trace-log.vue'),
      meta: {
        title: 'trace-log',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-log'
          }
        ]
      }
    },
    {
      path: '/log/terminal',
      name: 'TerminalLogs',
      component: () => import('@/views/log/terminal-log/index.vue'),
      meta: {
        title: 'terminal-log',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-log'
          }
        ]
      }
    },
    {
      path: '/log/remote',
      name: 'remoteLogs',
      component: () => import('@/views/terminal/remote/remote-list/index.vue'),
      meta: {
        title: 'terminal-remote-log',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-log'
          }
        ]
      }
    }
  ]
}
