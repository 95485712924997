import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import user from './modules/user'
import app from './modules/app'
import monitor from './modules/monitor'
import file from './modules/file'
import param from './modules/param'
import terminal from './modules/terminal'
import dashboard from './modules/dashboard'
import report from './modules/report'
import log from './modules/log'
import getters from './getters'
import category from './modules/category'
import help from './modules/help'
import system from './modules/system'
import globalParams from './modules/globalParams'
import tenant from './modules/tenant'
import geoFence from './modules/geoFence'

const store = createStore({
  state() {
    return {
      name: 'wisecard'
    }
  },
  getters,
  modules: {
    user,
    app,
    monitor,
    file,
    terminal,
    param,
    dashboard,
    log,
    report,
    category,
    help,
    system,
    globalParams,
    tenant,
    geoFence
  },
  plugins: [
    // 使用持久化存储vuex数据可以避免在刷新时vuex数据丢失的问题
    createPersistedState({
      storage: window.sessionStorage,
      reducer(val) {
        return {
          // 定义需要持久化存储的数据
          userInfo: val.user.userInfo
        }
      }
    })
  ]
})

export function initialStore() {
  // 初始化基础数据
  store.dispatch('user/getMerchantList')
  store.dispatch('user/getUserInfo')
  store.dispatch('globalParams/getTenantList')
}

export function setupStore(app) {
  app.use(store)
}

export default store
