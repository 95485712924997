import moment from 'moment'
import mitt from 'mitt'
import { handleMessageCommit, handleLogout } from '@/utils/globalHandler'
import momentTimeZone from 'moment-timezone'

export const registerProperties = (app) => {
  app.config.globalProperties.$filters = {
    fmtDateTime(time) {
      if (time && time.epochSecond) {
        const value = time.epochSecond * 1000
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      } else {
        return ''
      }
    },
    fmtDate(time) {
      if (time && time.epochSecond) {
        const value = time.epochSecond * 1000
        return moment(value).format('YYYY-MM-DD')
      } else {
        return ''
      }
    },

    getCurrentSystemTimeFormat(
      utcTime,
      timeZone = momentTimeZone.tz.guess(true),
      formatString = 'YYYY-MM-DD HH:mm:ss'
    ) {
      if (!utcTime) return ''
      // 使用传入的UTC时间字符串来创建一个moment对象
      const momentUTC = momentTimeZone.utc(utcTime)

      // 将UTC时间转换为指定的时区
      const momentLocal = momentUTC.tz(timeZone)

      // 使用指定的格式字符串来格式化时间
      const formattedTime = momentLocal.format(formatString)

      // 返回格式化后的时间字符串
      return formattedTime
    }
  }

  const bus = mitt()
  app.config.globalProperties.$bus = bus
  app.config.globalProperties.$handleMessageCommit = handleMessageCommit
  app.config.globalProperties.$handleLogout = handleLogout
  app.config.globalProperties.$SunloginControl = window.SunloginControl
}
