import SvgIcon from '@/components/SvgIcon'

// 通过 require.context() 函数来创建自己的 context
const svgRequire = require.context('./svg', false, /\.svg$/)
svgRequire.keys().forEach((svgIcon) => {
  svgRequire(svgIcon)
})
export const registerGlobIcons = (app) => {
  app.component('svg-icon', SvgIcon)
}
