import request from '../utils/request'

export const getCommonParams = (param) => {
  return request({
    url: `/rest/dict/code/${param}`,
    method: 'get'
  })
}

export const getLimitsList = () => {
  return request({
    url: '/rest/velocity/limit/list',
    method: 'get'
  })
}

export const getTerminalModelList = () => {
  return request({
    url: '/rest/terminalModel/option',
    method: 'get'
  })
}

export const getModuleList = () => {
  return request({
    url: '/rest/log/module/option',
    method: 'post'
  })
}

export const getOperationTypeList = () => {
  return request({
    url: '/rest/log/operationType/option',
    method: 'post'
  })
}

export const getTerminalModelListByTenantId = (tenantId) => {
  return request({
    url: `rest/terminalModel/option?tenantId=${tenantId}`,
    method: 'get'
  })
}

export const getTenantList = () => {
  return request({
    url: '/rest/tenant/getTenantTier',
    method: 'get'
  })
}

export const getTerminalList = () => {
  return request({
    url: '/rest/terminal/option',
    method: 'get'
  })
}

export const getUnselectTerminalList = () => {
  return request({
    url: '/rest/terminal/option/unselect',
    method: 'get'
  })
}

export const getTerminalParamGroupList = () => {
  return request({
    url: '/rest/terminalParam/group/option',
    method: 'get'
  })
}

export const getParamTemplateList = () => {
  return request({
    url: '/rest/paramTemplate/option',
    method: 'get'
  })
}

export const getGeoFenceGroupList = () => {
  return request({
    url: '/rest/geoFence/group/option',
    method: 'get'
  })
}

export const getGeoFenceList = () => {
  return request({
    url: '/rest/geoFence/option',
    method: 'get'
  })
}
