/*****
 * @desc use to handle the context of user
 * @author Blank
 * @date 2022-11-29
 */
import {
  getMonitorStatusList,
  getMonitorStatusDetailBySn,
  getMonitorOtaList,
  getMonitorAppList,
  getMonitorParamList,
  getMonitorOtaDetailList,
  getMonitorAppDetailList,
  getMonitorParamDetailList,
  getOnlineTerminals,
  deleteTerminalOnline,
  getBatchJobsList,
  getBatchJobsDetail,
  getBatchJobsLogs
} from '../../service/monitor'

export default {
  namespaced: true,
  state: () => ({
    monitorStatusCount: 0,
    monitorStatusList: [],
    monitorOtaCount: 0,
    monitorOtaList: [],
    monitorAppCount: 0,
    monitorAppList: [],
    monitorParamCount: 0,
    monitorParamList: [],
    onlineTerminalCount: 0,
    onlineTerminalList: [],
    batchJobList: [],
    batchJobCount: 0,
    batchJobLogsList: [],
    batchJobLogsCount: 0,
    monitorOtaDetailCount: 0,
    monitorOtaDetailList: [],
    monitorAppDetailCount: 0,
    monitorAppDetailList: [],
    monitorParamDetailCount: 0,
    monitorParamDetailList: []
  }),
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    setOnlineTerminalCount(state, onlineTerminalCount) {
      state.onlineTerminalCount = onlineTerminalCount
    },
    setOnlineTerminalList(state, onlineTerminalList) {
      state.onlineTerminalList = onlineTerminalList
    },
    setMonitorStatusCount(state, monitorStatusCount) {
      state.monitorStatusCount = monitorStatusCount
    },
    setMonitorStatusList(state, monitorStatusList) {
      state.monitorStatusList = monitorStatusList
    },
    setMonitorOtaCount(state, monitorOtaCount) {
      state.monitorOtaCount = monitorOtaCount
    },
    setMonitorOtaList(state, monitorOtaList) {
      state.monitorOtaList = monitorOtaList
    },
    setMonitorAppCount(state, monitorAppCount) {
      state.monitorAppCount = monitorAppCount
    },
    setMonitorAppList(state, monitorAppList) {
      state.monitorAppList = monitorAppList
    },
    setMonitorParamCount(state, monitorParamCount) {
      state.monitorParamCount = monitorParamCount
    },
    setMonitorParamList(state, monitorParamList) {
      state.monitorParamList = monitorParamList
    },
    setBatchJobsList(state, batchJobList) {
      state.batchJobList = batchJobList
    },
    setBatchJobsCount(state, batchJobCount) {
      state.batchJobCount = batchJobCount
    },
    setBatchJobsLogsList(state, batchJobLogsList) {
      state.batchJobLogsList = batchJobLogsList
    },
    setBatchJobsLogsCount(state, batchJobLogsCount) {
      state.batchJobLogsCount = batchJobLogsCount
    },
    setMonitorOtaDetailCount(state, monitorOtaDetailCount) {
      state.monitorOtaDetailCount = monitorOtaDetailCount
    },
    setMonitorOtaDetailList(state, monitorOtaDetailList) {
      state.monitorOtaDetailList = monitorOtaDetailList
    },
    setMonitorAppDetailCount(state, monitorAppDetailCount) {
      state.monitorAppDetailCount = monitorAppDetailCount
    },
    setMonitorAppDetailList(state, monitorAppDetailList) {
      state.monitorAppDetailList = monitorAppDetailList
    },
    setMonitorParamDetailCount(state, monitorParamDetailCount) {
      state.monitorParamDetailCount = monitorParamDetailCount
    },
    setMonitorParamDetailList(state, monitorParamDetailList) {
      state.monitorParamDetailList = monitorParamDetailList
    }
  },
  actions: {
    async getOnlineTerminalList(context, payload) {
      const res = await getOnlineTerminals(payload)
      this.commit(
        'monitor/setOnlineTerminalList',
        res?.data?.data?.records || []
      )
      this.commit('monitor/setOnlineTerminalCount', res?.data?.data?.total || 0)
      return res
    },
    async getMonitorStatusDetailBySn({ dispatch }, payload) {
      const res = await getMonitorStatusDetailBySn(payload)
      return res?.data?.data || {}
    },
    async getMonitorStatusList(context, payload) {
      const res = await getMonitorStatusList(payload)
      this.commit(
        'monitor/setMonitorStatusList',
        res?.data?.data?.records || []
      )
      this.commit('monitor/setMonitorStatusCount', res?.data?.data?.total || 0)
      return res
    },
    async getMonitorOtaList(context, payload) {
      const res = await getMonitorOtaList(payload)
      this.commit('monitor/setMonitorOtaList', res?.data?.data?.records || [])
      this.commit('monitor/setMonitorOtaCount', res?.data?.data?.total || 0)
      return res
    },
    async getMonitorParamList(context, payload) {
      const res = await getMonitorParamList(payload)
      this.commit('monitor/setMonitorParamList', res?.data?.data?.records || [])
      this.commit('monitor/setMonitorParamCount', res?.data?.data?.total || 0)
      return res
    },
    async getMonitorAppList(context, payload) {
      const res = await getMonitorAppList(payload)
      this.commit('monitor/setMonitorAppList', res?.data?.data?.records || [])
      this.commit('monitor/setMonitorAppCount', res?.data?.data?.total || 0)
      return res
    },
    async handleDeleteTerminal({ dispatch }, payload) {
      const res = await deleteTerminalOnline(payload)
      return res
    },
    async getBatchJobsList(context, payload) {
      const res = await getBatchJobsList(payload)
      this.commit('monitor/setBatchJobsList', res?.data?.data?.records || [])
      this.commit('monitor/setBatchJobsCount', res?.data?.data?.total || 0)
      return res
    },
    async getBatchJobsDetail(context, payload) {
      const res = await getBatchJobsDetail(payload)
      return res
    },
    async getBatchJobsLogs(context, payload) {
      const res = await getBatchJobsLogs(payload)
      this.commit(
        'monitor/setBatchJobsLogsList',
        res?.data?.data?.records || []
      )
      this.commit('monitor/setBatchJobsLogsCount', res?.data?.data?.total || 0)
      return res
    },
    async getMonitorAppDetailList(context, payload) {
      const res = await getMonitorAppDetailList(payload)
      this.commit(
        'monitor/setMonitorAppDetailList',
        res?.data?.data?.records || []
      )
      this.commit(
        'monitor/setMonitorAppDetailCount',
        res?.data?.data?.total || 0
      )
      return res
    },
    async getMonitorOtaDetailList(context, payload) {
      const res = await getMonitorOtaDetailList(payload)
      this.commit(
        'monitor/setMonitorOtaDetailList',
        res?.data?.data?.records || []
      )
      this.commit(
        'monitor/setMonitorOtaDetailCount',
        res?.data?.data?.total || 0
      )
      return res
    },
    async getMonitorParamDetailList(context, payload) {
      const res = await getMonitorParamDetailList(payload)
      this.commit(
        'monitor/setMonitorParamDetailList',
        res?.data?.data?.records || []
      )
      this.commit(
        'monitor/setMonitorParamDetailCount',
        res?.data?.data?.total || 0
      )
      return res
    }
  }
}
