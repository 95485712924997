const throttle = {
  // 初始化指令
  beforeMount(el, binding, vnode) {
    let throttleTime = binding.value
    if (!throttleTime) throttleTime = 2000
    let cbFun
    el.addEventListener(
      'click',
      (event) => {
        if (!cbFun) {
          // 第一次执行
          cbFun = setTimeout(() => {
            cbFun = null
          }, throttleTime)
        } else {
          event && event.stopImmediatePropagation()
        }
      },
      true
    )
  },
  beforeUnmount(el, binding) {
    // 解除事件监听
    document.removeEventListener('click', el.__vueClickOutside__)
    delete el.__vueClickOutside__
  }
}

export default throttle
