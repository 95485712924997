import layout from '@/Layout/index.vue'

export default {
  path: '/home',
  component: layout,
  redirect: '/home',
  meta: {
    title: 'home'
  },
  children: [
    {
      path: '/file/app',
      name: 'Applications',
      component: () =>
        import('@/views/application/application-list/applications.vue'),
      meta: {
        title: 'application-overview',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-manage'
          }
        ]
      }
    },
    {
      path: '/file/app/new',
      name: 'ApplicationNew',
      component: () =>
        import('@/views/application/application-handle/application-handle.vue'),
      meta: {
        title: 'application-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/file/app',
        parentTitle: [
          {
            title: 'app-manage'
          },
          {
            title: 'app-apps-overview',
            path: '/file/app'
          }
        ]
      }
    },
    {
      path: '/file/app/view/:id',
      name: 'ApplicationView',
      component: () =>
        import('@/views/application/application-handle/application-handle.vue'),
      meta: {
        title: 'application-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/file/app',
        parentTitle: [
          {
            title: 'app-manage'
          },
          {
            title: 'app-apps-overview',
            path: '/file/app'
          }
        ]
      }
    },
    {
      path: '/file/app/import/:type',
      name: 'ApplicationFileImport',
      component: () =>
        import('@/views/application/application-file-handle/index.vue'),
      meta: {
        title: 'application-import',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/file/app',
        parentTitle: [
          {
            title: 'app-manage'
          },
          {
            title: 'app-apps-overview',
            path: '/file/app'
          }
        ]
      }
    },
    {
      path: '/file/app/edit/:id',
      name: 'ApplicationEdit',
      component: () =>
        import('@/views/application/application-handle/application-handle.vue'),
      meta: {
        title: 'application-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/file/app',
        parentTitle: [
          {
            title: 'app-manage'
          },
          {
            title: 'app-apps-overview',
            path: '/file/app'
          }
        ]
      }
    },
    {
      path: '/file/ota',
      name: 'ota',
      component: () => import('@/views/application/ota/ota-list/otas.vue'),
      meta: {
        title: 'ota',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-manage'
          }
        ]
      }
    },
    {
      path: '/file/ota/new',
      name: 'OtaNew',
      component: () =>
        import('@/views/application/ota/ota-handle/ota-handle.vue'),
      meta: {
        title: 'ota-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/file/ota',
        parentTitle: [
          {
            title: 'app-manage'
          },
          {
            title: 'app-ota-embedded-files-overview',
            path: '/file/ota'
          }
        ]
      }
    },
    {
      path: '/file/ota/import',
      name: 'OtaFileImport',
      component: () =>
        import('@/views/application/ota/ota-file-handle/index.vue'),
      meta: {
        title: 'ota-import',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/file/ota',
        parentTitle: [
          {
            title: 'app-manage'
          },
          {
            title: 'app-ota-embedded-files-overview',
            path: '/file/ota'
          }
        ]
      }
    },
    {
      path: '/file/ota/view/:id',
      name: 'OtaView',
      component: () =>
        import('@/views/application/ota/ota-handle/ota-handle.vue'),
      meta: {
        title: 'ota-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/file/ota',
        parentTitle: [
          {
            title: 'app-manage'
          },
          {
            title: 'app-ota-embedded-files-overview',
            path: '/file/ota'
          }
        ]
      }
    },
    {
      path: '/file/ota/edit/:id',
      name: 'OtaEdit',
      component: () =>
        import('@/views/application/ota/ota-handle/ota-handle.vue'),
      meta: {
        title: 'ota-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/file/ota',
        parentTitle: [
          {
            title: 'app-manage'
          },
          {
            title: 'app-ota-embedded-files-overview',
            path: '/file/ota'
          }
        ]
      }
    },
    {
      path: '/file/apphome',
      name: 'welcomePageList',
      component: () =>
        import('@/views/application/welcome-page-list/welcome-pages.vue'),
      meta: {
        title: 'welcome-page',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-manage'
          }
        ]
      }
    },
    {
      path: '/file/apphome/new',
      name: 'welcomePageNew',
      component: () =>
        import(
          '@/views/application/welcome-page-handle/welcome-page-handle.vue'
        ),
      meta: {
        title: 'welcome-page-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/file/apphome',
        parentTitle: [
          {
            title: 'app-manage'
          },
          {
            title: 'app-welcome-page',
            path: '/file/apphome'
          }
        ]
      }
    },
    {
      path: '/file/apphome/view/:id',
      name: 'welcomePageView',
      component: () =>
        import(
          '@/views/application/welcome-page-handle/welcome-page-handle.vue'
        ),
      meta: {
        title: 'welcome-page-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/file/apphome',
        parentTitle: [
          {
            title: 'app-manage'
          },
          {
            title: 'app-welcome-page',
            path: '/file/apphome'
          }
        ]
      }
    },
    {
      path: '/file/apphome/edit/:id',
      name: 'WelcomePageEdit',
      component: () =>
        import(
          '@/views/application/welcome-page-handle/welcome-page-handle.vue'
        ),
      meta: {
        title: 'welcome-page-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/file/apphome',
        parentTitle: [
          {
            title: 'app-manage'
          },
          {
            title: 'app-welcome-page',
            path: '/file/apphome'
          }
        ]
      }
    }
  ]
}
