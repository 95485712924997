import { generateColors } from '../utils/theme'

const getters = {
  cssVar: (state) => {
    return {
      ...state.app.variables,
      ...generateColors(state.app.mainColor)
    }
  },
  // home
  mainColor: (state) => state.app.mainColor,
  sidebarOpened: (state) => state.app.sidebarOpened,
  tagsViewList: (state) => state.app.tagsViewList,
  language: (state) => state.app.language,

  // user
  usersCount: (state) => state.user.usersCount,
  usersList: (state) => state.user.usersList,
  allUserList: (state) => state.user.allUserList,
  userInfo: (state) => state.user.userInfo,
  roleCount: (state) => state.user.roleCount,
  terminalCount: (state) => state.user.terminalCount,
  terminalList: (state) => state.user.terminalList,
  userLogsCount: (state) => state.user.userLogsCount,
  userLogsList: (state) => state.user.userLogsList,
  localeJson: (state) => state.user.localeJson,
  resourcesCount: (state) => state.user.resourcesCount,
  resourcesList: (state) => state.user.resourcesList,
  resuorceMenuBtnList: (state) => state.user.resuorceMenuBtnList,

  // category
  categoriesList: (state) => state.category.categoriesList,
  categoriesCount: (state) => state.category.categoriesCount,

  // card
  cardsList: (state) => state.card.cardsList,
  cardsCount: (state) => state.card.cardsCount,

  // monitor
  onlineTerminalList: (state) => state.monitor.onlineTerminalList,
  onlineTerminalCount: (state) => state.monitor.onlineTerminalCount,

  // dashboard
  customerCount: (state) => state.dashboard.customerCount,
  customerRate: (state) => state.dashboard.customerRate,
  merchantCount: (state) => state.dashboard.merchantCount,
  merchantRate: (state) => state.dashboard.merchantRate,
  dashboardTerminalCount: (state) => state.dashboard.terminalCount,
  dashboardTerminalRate: (state) => state.dashboard.terminalRate,

  // customer
  customerXLabels: (state) => state.report.customerXLabels,
  customerReportValues: (state) => state.report.customerReportValues,

  // merchant
  merchantXLabels: (state) => state.report.merchantXLabels,
  merchantReportValues: (state) => state.report.merchantReportValues,

  // app
  applicationXLabels: (state) => state.report.applicationXLabels,
  applicationReportValues: (state) => state.report.applicationReportValues,

  // terminal
  terminalXLabels: (state) => state.report.terminalXLabels,
  terminalReportValues: (state) => state.report.terminalReportValues,
  // model
  modelsList: (state) => state.terminal.modelsList
}
export default getters
