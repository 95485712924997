import request from '../utils/request'

export const getDashboardStatistic = (payload) => {
  return request({
    url: `/rest/statistic/basic?date=${payload.date}`,
    method: 'get'
  })
}

export const getTerminalDailyStatistics = (data) => {
  return request({
    url: '/rest/statistic/terminal/added/daily',
    data,
    method: 'post'
  })
}

export const getRecentUnreadMessage = () => {
  return request({
    url: `/rest/statistic/message/unread`,
    method: 'get'
  })
}

export const getRecentOperators = () => {
  return request({
    url: `/rest/statistic/log/operation`,
    method: 'get'
  })
}

export const getTerminalStatusStatistics = () => {
  return request({
    url: '/rest/statistic/terminal/status/now',
    method: 'get'
  })
}
