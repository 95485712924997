<template>
  <div>
    <el-dropdown
      v-bind="$attrs"
      trigger="click"
      class="theme"
      @command="handleSetTheme"
    >
      <div>
        <el-tooltip :content="$t('general.theme-select')" effect="light">
          <svg-icon
            class="change-theme-icon"
            id="guide-theme"
            icon="change-theme"
            style="
              width: 20px;
              height: 20px;
              display: table-cell;
              vertical-align: middle;
              margin-top: 3px;
            "
          />
        </el-tooltip>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="color">
            {{ $t('general.change-theme') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <!-- 展示弹出层 -->
    <div>
      <select-color v-model="selectColorVisible"></select-color>
    </div>
  </div>
</template>

<script setup>
import SelectColor from './component/selectColor'
import { ref } from 'vue'

const selectColorVisible = ref(false)
const handleSetTheme = (command) => {
  selectColorVisible.value = true
}
</script>

<style lang="scss" scoped>
.change-theme-icon {
  width: 18px;
}
</style>
